import { Box, FormHelperText, Link, Typography } from "@material-ui/core";
import SubmitButton from "components/Form/button/SubmitButton";
import RadioGrp from "components/Form/Radio/RadioGrp";
import SquareTextField from "components/Form/TextField/SquareTextField";
import { Formik } from "formik";
import { useAuth, useMounted } from "hooks";
import { FC, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { MailOutline, Visibility, VisibilityOff } from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
import { getCountryCode, setCountryCode } from "utils/general";
const LoginForm: FC = (props) => {
  const { REACT_APP_COUNTRY_ID } = process.env;

  const mounted = useMounted();
  const { login, masterData } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  // let countrydata: any = null;
  // const [countrydata, setCountryData] = useState(COUNTRY_ID);
  let cuntoryOption =
    masterData.countries.length > 0
      ? masterData.countries.map((data) => {
          return {
            label: data?.name,
            value: data?.id,
          };
        })
      : [];
  // console.log("Master data ", masterData.countries);
  // useEffect(() => {
  //   let path = window.location.origin;
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const params: any = Object.fromEntries(urlSearchParams.entries());
  //   console.log("params", params);
  //   if (params?.country == "1") {
  //     setCountryData(1);
  //     setCountryCode(1);
  //   } else if (params?.country == "2") {
  //     setCountryData(2);
  //     setCountryCode(2);
  //   } else {
  //     setCountryData(3);
  //     setCountryCode(3);
  //   }
  //   // console.log(
  //   //   "Data Test",
  //   //   path,
  //   //   countrydata,
  //   //   window.location,
  //   //   "REACT_APP_US_URL",
  //   //   REACT_APP_US_URL,
  //   //   "REACT_APP_GERMANY_URL",
  //   //   REACT_APP_GERMANY_URL
  //   // );
  //   let countryIdCokie = getCountryCode();
  //   // console.log("countryIdCokie", countryIdCokie);
  //   if (countryIdCokie == 1) {
  //     setCountryData(1);
  //     setCountryCode(1);
  //   } else if (countryIdCokie == 2) {
  //     setCountryData(2);
  //     setCountryCode(2);
  //   } else {
  //     setCountryData(3);
  //     setCountryCode(3);
  //   }
  // }, []);
  // console.log("Country", country);
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: "",
        password: "",
        country: +REACT_APP_COUNTRY_ID,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password);
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        handleBlur,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Box sx={{ mt: 2, mb: 1 }}>
            <SquareTextField
              label="Email"
              name="email"
              id="email"
              value={values.email}
              error={Boolean(errors.email && touched.email)}
              helperText={errors.email && touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MailOutline />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ mt: 0, mb: 2 }}>
            <SquareTextField
              label="Password"
              name="password"
              id="password"
              value={values.password}
              error={Boolean(errors.password && touched.password)}
              helperText={
                errors.password && touched.password && errors.password
              }
              onBlur={handleBlur}
              onChange={handleChange}
              type={!showPassword ? "password" : "text"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <RadioGrp
            data={cuntoryOption}
            defaultValue={values.country}
            onChange={(data) => {
              setCountryCode(data.target.value);
              setFieldValue("country", +data.target.value);
              const {
                REACT_APP_US_URL,
                REACT_APP_GERMANY_URL,
                REACT_APP_INTERNAL_URL,
              } = process.env;
              if (data.target.value == 1) {
                window.location.replace(`${REACT_APP_US_URL}`);
              } else if (data.target.value == 3) {
                window.location.replace(`${REACT_APP_INTERNAL_URL}`);
              } else {
                window.location.replace(`${REACT_APP_GERMANY_URL}`);
              }
            }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          {process.env.REACT_APP_REGION === "eu" && (
            <Box>
              <Typography color="ActiveBorder" sx={{ my: 1 }}>
                By proceeding, you agree to the{" "}
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="https://www.speck-pumps.com/de/badu/datenschutzerklaerung/?v=3a52f3c22ed6"
                  target="_blank"
                  sx={{ my: 2, textDecoration: "underline" }}
                >
                  {t("Privat Policy")}
                </Link>{" "}
                and{" "}
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="https://www.speck-pumps.com/de/badu/impressum/?v=3a52f3c22ed6"
                  target="_blank"
                  sx={{ my: 2, textDecoration: "underline" }}
                >
                  {t("Impressum")}
                </Link>
                .
              </Typography>
            </Box>
          )}

          <Box className={`${styles.loginFormButtonGroup}`}>
            <Box>
              <SubmitButton
                disabled={isSubmitting}
                label={t("Log In")}
                size="large"
                type="submit"
                sx={{ mt: 2, mb: 2, minWidth: "120px" }}
              />
            </Box>
            <Box sx={{}}>
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/auth/password-recovery"
                sx={{ mt: 2, mb: 2 }}
              >
                {t("Forgot password?")}
              </Link>
            </Box>
          </Box>
          <Box className={`${styles.loginFormlink}`}>
            <Link
              color="textSecondary"
              component={RouterLink}
              to="/auth/sign-up"
              sx={{ mt: 2, mb: 2 }}
            >
              {t("Don’t have an account?")} <b>{t("Sign up")}</b>
            </Link>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
