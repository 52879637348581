export const SocketAction = {
  connection: "connection",
  CLIENT: {
    ENABLE_RELAY: "ENABLE_RELAY",
    DISABLE_RELAY: "DISABLE_RELAY",

    ENABLE_SCHEDULE: "ENABLE_SCHEDULE",
    DISABLE_SCHEDULE: "DISABLE_SCHEDULE",

    INCREASE_MOTOR_SPEED: "INCREASE_MOTOR_SPEED",
    DECREASE_MOTOR_SPEED: "DECREASE_MOTOR_SPEED",

    SET_DEVICE_TYPE: "SET_DEVICE_TYPE",

    CLEAR_WIFI_SSID: "wifi_clear",
    TURN_OFF_ALL_PORTS: "set_all_off",
    RTC_VOLTAGE: "get_rtc_voltage",
    GET_DEVICE_TYPE: "get_device_type",
    GET_DEVICE_TIMEZONE: "get_timezone",
    GET_FW_VERSION: "get_fw_version",
    GET_WIFI_SSID: "wifi_ssid",
    GET_DEVICE_TIME: "get_device_time",
    GET_PORT_STATUS: "get_port_status",
    SET_PORT_STATUS: "set_port_status",
    SET_PORT_DISABLE: "set_port_disable",
    GET_PORT_TIME_TO_OFF: "get_port_time_to_off",
    GET_PUMP_LOCK_STATUS: "get_pump_lock",
    GET_SCHEDULER: "get_scheduler",
    GET_OP_MODE: "get_op_mode",
    GET_FLOODED_STATE: "get_flood_state",
    GET_SPEED: "get_speed",
    SET_SPEED: "set_speed",
    SET_PAUSE_PORT: "set_pause_port",

    OTA: "ota",
    OTA_INITIATED: "OTA_INITIATED",
    OTA_FINISHED: "OTA_FINISHED",
    OTA_FAILED: "OTA_FAILED",
    OTA_TERMINATED: "OTA_TERMINATED",
    OTA_DOWNLOADING_UPDATE: "OTA_DOWNLOADING_UPDATE",
    SET_DISABLED_MODE: "SET_DISABLED_MODE",
    RESUME_SCHEDULE: "RESUME_SCHEDULE",
    CONFIGURE_PRIMING_SETTING: "CONFIGURE_PRIMING_SETTING",
  },

  SERVER: {
    SEND_ROOM_MESSAGE: "SEND_ROOM_MESSAGE",
    JOIN_ROOM: "JOIN_ROOM",
    ENABLE_DEVICE: "ENABLE_DEVICE",
    DISABLE_DEVICE: "DISABLE_DEVICE",
    UPDATE_DEVICE: "UPDATE_DEVICE",
    DEVICE_ADDED: "DEVICE_ADDED",

    DEVICE_UPDATED: "DEVICE_UPDATED",
    DEVICE_ACCESS_GRANTED: "DEVICE_ACCESS_GRANTED",
    DEVICE_ACCESS_DELETED: "DEVICE_ACCESS_DELETED",
    DEVICE_DELETED: "DEVICE_DELETED",

    SCHEDULE_ADDED: "SCHEDULE_ADDED",
    SCHEDULE_DELETED: "SCHEDULE_DELETED",
    SCHEDULE_UPDATED: "SCHEDULE_UPDATED",
    SCHEDULE_ENABLED_ALL: "SCHEDULE_ENABLED_ALL",
    SCHEDULE_DISABLE_ALL: "SCHEDULE_DISABLE_ALL",

    RELAY_ADDED: "RELAY_ADDED",
    RELAY_UPDATED: "RELAY_UPDATED",
    RELAY_DELETED: "RELAY_DELETED",
  },
  ADMIN: {
    AUDIT: {
      ADMIN_LOGS: "ADMIN_LOGS",
      USER_LOGS: "USER_LOGS",
    },
    USERS_COUNT: "USERS_COUNT",
    CONNECTED_DEVICES: "CONNECTED_DEVICES",
    SOCKET_CONNECTIONS: "SOCKET_CONNECTIONS",
    DEVICES_COUNT: "DEVICES_COUNT",
  },
};
