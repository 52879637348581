import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import type { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks";
import { is } from "date-fns/locale";

const Breadcrumb: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { t } = useTranslation();

  const { user } = useUser();
  const isAdminUser = user?.profile?.role_id === 1;

  const breadcrumbItemsUser = [
    "profile-management",
    "device-management",
    "help",
    "settings",
  ];

  const breadcrumbItemsAdmin = [
    "user-management",
    "user-email-management",
    "sub-admin-management",
    "tutorials",
    "sort-tutorial",
    "schedule-management",
    "info-point-management",
    "device-configuration",
    "motor-management",
    "device-types",
    "device-models",
    "device-firmwares",
    "trail",
    "active-user-report",
    "activity-log",
    "user-session",
    "terms-and-condition",
    "help",
    "feedback",
    "settings",
    "notifications",
  ];

  let breadcrumbItems;
  if (isAdminUser) {
    breadcrumbItems = pathnames.filter((item1) =>
      breadcrumbItemsAdmin.includes(item1)
    );
  } else {
    breadcrumbItems = pathnames.filter((item1) =>
      breadcrumbItemsUser.includes(item1)
    );
  }

  const homeRedirectUrl = isAdminUser ? "/" : "/management/device-management";

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator="|"
      sx={{
        display: "inline-block",
        margin: "0.8rem 0",
        fontSize: "0.8rem",
      }}
    >
      <Link color="inherit" onClick={() => navigate(homeRedirectUrl)}>
        {t("Home")}
      </Link>
      {location.pathname === "/" && (
        <Link color="inherit" onClick={() => navigate("/")}>
          {t("Dashboard")}
        </Link>
      )}
      {breadcrumbItems.map((name, index) => {
        name = name.replace(/-/g, " "); // replace all '-' into space
        name = name
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
        const routeTo = `/${breadcrumbItems.slice(0, index + 1).join("/")}`;
        // console.log("Route To ", routeTo);
        const isLast = index === breadcrumbItems.length - 1;
        return isLast ? (
          <Typography key={name} color="primary">
            {t(name)}
          </Typography>
        ) : (
          <Link key={name} onClick={() => navigate(routeTo)} color="inherit">
            {t(name)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
