import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useReducer } from "react";
import { useAuth } from "../hooks";
import {
  DeviceContextValue,
  State,
} from "./Interface/UserDeviceManament/UserDevcieManagmetInterface";
import {
  Action,
  AddNewMConfigrationData,
  AddNewMFirmWareData,
  AddNewMModelData,
  AddNewMotorData,
  AddNewMTypeData,
  DeleteCoonfigrationDataset,
  DeleteFirmWareDataset,
  DeleteModelDataset,
  DeleteMoterDataset,
  DeleteTypeDataset,
  GetCongigrtionList,
  GetFirmWareList,
  GetModelList,
  GetMotorList,
  GetSheduleListList,
  GetTypeList,
  UpdateConfigrationData,
  UpdateFirmWareData,
  UpdateModelData,
  UpdateMotorData,
  UpdateSheduleListData,
  UpdateTypeData,
  userfirmwareUpdateList,
  updateUserFirmwareUpdateListData,
  RemoveUserFirmwareUpdateListData,
  SetSelectedFirmwareData,
} from "./Interface/UserDeviceManament/UserDeviceManamentActions";
// Motor List Interface
const initialState: State = {
  motorList: [],
  configrationList: [],
  deviceTypeArray: [],
  deviceModelesList: [],
  deviceFirmWareList: [],
  sheduleDevicesList: [],
  sheduleDevicesListPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  motorPaination: {
    count: 0,
    page: 0,
    size: 10,
  },
  configrationPaination: {
    count: 0,
    page: 0,
    size: 10,
  },
  deviceTypePaination: {
    count: 0,
    page: 0,
    size: 10,
  },
  deviceModelesPaination: {
    count: 0,
    page: 0,
    size: 10,
  },
  deviceFirmWarePaination: {
    count: 0,
    page: 0,
    size: 10,
  },
  firmwaeArrayForUpdate: [],
  selectedFirmWare: {
    owner_id: 0,
    connection_state: 0,
    id: 0,
    fw_version: 0,
    hw_version: 0,
    nickname: "",
    device_id: 0,
    device_model: {
      id: 0,
      device_type_id: 0,
      configuration_id: 0,
    },
    new_firmware: {
      status: 0,
      device_type_id: 0,
      id: 0,
      version: "",
      version_name: "",
    },
  },
};
const DeviceContext = createContext<DeviceContextValue>({
  ...initialState,
  setMortorListData: () => Promise.resolve(),
  addMoterDataSet: () => Promise.resolve(),
  udpateDataSet: () => Promise.resolve(),
  removeDatafromSet: () => Promise.resolve(),

  setConfigListData: () => Promise.resolve(),
  addConfigDataSet: () => Promise.resolve(),
  udpateConfigDataSet: () => Promise.resolve(),
  removeConfigDatafromSet: () => Promise.resolve(),

  setTypesListData: () => Promise.resolve(),
  addTypesDataSet: () => Promise.resolve(),
  udpateTypesDataSet: () => Promise.resolve(),
  removeTypesDatafromSet: () => Promise.resolve(),

  setModelListData: () => Promise.resolve(),
  addModelDataSet: () => Promise.resolve(),
  udpateModelDataSet: () => Promise.resolve(),
  removeModelDatafromSet: () => Promise.resolve(),

  setFirmWareListData: () => Promise.resolve(),
  addFirmWareDataSet: () => Promise.resolve(),
  udpateFirmWareDataSet: () => Promise.resolve(),
  removeFirmWareDatafromSet: () => Promise.resolve(),

  setSheduleListData: () => Promise.resolve(),
  udpateSheduleDataSet: () => Promise.resolve(),

  updatedListOfFirmware: () => Promise.resolve(),
  updateListofFirmwareData: () => Promise.resolve(),
  removerListDataOfFirmware: () => Promise.resolve(),
  setSelectedDatatOfFirmWae: () => Promise.resolve(),
});
const handlers: Record<string, (state: State, action: Action) => State> = {
  // Device Motor Managment functions.
  GET_MOTOR_LIST: (state: State, action: GetMotorList): State => {
    const motorListArray = [...action.payload.rows];
    const motorDataPagination = {
      ...state.motorPaination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      motorList: motorListArray,
      motorPaination: motorDataPagination,
    };
  },
  ADD_MOTOR_LIST_DATA: (state: State, action: AddNewMotorData): State => {
    let listDataForAdd = [...state.motorList];
    if (state.motorList.length < state.motorPaination.size) {
      listDataForAdd.push(action.payload);
    }
    let afterCreateMotorPagination = {
      ...state.motorPaination,
      count: state.motorPaination.count + 1,
    };
    return {
      ...state,
      motorList: listDataForAdd,
      motorPaination: afterCreateMotorPagination,
    };
  },
  Update_MOTOR_LIST_DATA: (state: State, action: UpdateMotorData): State => {
    let listDataForUpdate = [...state.motorList];
    let listDataForUpdateIndex = listDataForUpdate.findIndex((data) => {
      return data.id === action.payload.id;
    });
    if (listDataForUpdateIndex !== -1) {
      listDataForUpdate[listDataForUpdateIndex] = {
        ...listDataForUpdate[listDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      motorList: listDataForUpdate,
    };
  },
  DELETE_MOTOR_LIST_DATA: (state: State, action: DeleteMoterDataset): State => {
    let listDataForDelete = [...state.motorList];
    let listDataForDeleteIndex = listDataForDelete.findIndex((data) => {
      return data.id === action.payload.id;
    });

    if (listDataForDeleteIndex !== -1) {
      listDataForDelete.splice(listDataForDeleteIndex, 1);
    }
    let afterDeleteMotorPagination = {
      ...state.motorPaination,
      count: state.motorPaination.count - 1,
    };
    return {
      ...state,
      motorList: listDataForDelete,
      motorPaination: afterDeleteMotorPagination,
    };
  },

  // Device Configration Managment functions.
  GET_CONFIGRATION_LIST: (state: State, action: GetCongigrtionList): State => {
    const configrationListArray = [...action.payload.rows];
    const configDataPagination = {
      ...state.configrationPaination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      configrationList: configrationListArray,
      configrationPaination: configDataPagination,
    };
  },
  ADD_CONFIGRATION_DATA: (
    state: State,
    action: AddNewMConfigrationData
  ): State => {
    let configrationlistDataForAdd = [...state.configrationList];
    if (state.configrationList.length < state.configrationPaination.size) {
      configrationlistDataForAdd.push(action.payload);
    }
    let afterCreateConfigPagination = {
      ...state.configrationPaination,
      count: state.configrationPaination.count + 1,
    };
    return {
      ...state,
      configrationList: configrationlistDataForAdd,
      configrationPaination: afterCreateConfigPagination,
    };
  },
  Update_CONFIGRATION_LIST_DATA: (
    state: State,
    action: UpdateConfigrationData
  ): State => {
    let configrationListDataForUpdate = [...state.configrationList];
    let configrationListDataForUpdateIndex =
      configrationListDataForUpdate.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (configrationListDataForUpdateIndex !== -1) {
      configrationListDataForUpdate[configrationListDataForUpdateIndex] = {
        ...configrationListDataForUpdate[configrationListDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      configrationList: configrationListDataForUpdate,
    };
  },
  DELETE_CONFIGRATION_LIST_DATA: (
    state: State,
    action: DeleteCoonfigrationDataset
  ): State => {
    let ConfiglistDataForDelete = [...state.configrationList];
    let ConfiglistDataForDeleteIndex = ConfiglistDataForDelete.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (ConfiglistDataForDeleteIndex !== -1) {
      ConfiglistDataForDelete.splice(ConfiglistDataForDeleteIndex, 1);
    }
    let afterDeleteConfigPagination = {
      ...state.configrationPaination,
      count: state.configrationPaination.count - 1,
    };
    return {
      ...state,
      configrationList: ConfiglistDataForDelete,
      configrationPaination: afterDeleteConfigPagination,
    };
  },
  // Device Types Managment functions.
  GET_TYPES_LIST: (state: State, action: GetTypeList): State => {
    const deviceTypesListArray = [...action.payload.rows];
    const typesDataPagination = {
      ...state.deviceTypePaination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      deviceTypeArray: deviceTypesListArray,
      deviceTypePaination: typesDataPagination,
    };
  },
  ADD_TYPES_DATA: (state: State, action: AddNewMTypeData): State => {
    let DeviceTypelistDataForAdd = [...state.deviceTypeArray];
    if (state.deviceTypeArray.length < state.deviceTypePaination.size) {
      DeviceTypelistDataForAdd.push(action.payload);
    }
    let afterCreateTypePagination = {
      ...state.deviceTypePaination,
      count: state.deviceTypePaination.count + 1,
    };
    return {
      ...state,
      deviceTypeArray: DeviceTypelistDataForAdd,
      deviceTypePaination: afterCreateTypePagination,
    };
  },
  Update_TYPES_LIST_DATA: (state: State, action: UpdateTypeData): State => {
    let deviceTypesListDataForUpdate = [...state.deviceTypeArray];
    let deviceTypesListDataForUpdateIndex =
      deviceTypesListDataForUpdate.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (deviceTypesListDataForUpdateIndex !== -1) {
      deviceTypesListDataForUpdate[deviceTypesListDataForUpdateIndex] = {
        ...deviceTypesListDataForUpdate[deviceTypesListDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      deviceTypeArray: deviceTypesListDataForUpdate,
    };
  },
  DELETE_TYPES_LIST_DATA: (state: State, action: DeleteTypeDataset): State => {
    let deviceTypeslistDataForDelete = [...state.deviceTypeArray];
    let deviceTypeslistDataForDeleteIndex =
      deviceTypeslistDataForDelete.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (deviceTypeslistDataForDeleteIndex !== -1) {
      deviceTypeslistDataForDelete.splice(deviceTypeslistDataForDeleteIndex, 1);
    }
    let afterDeleteTypesPagination = {
      ...state.deviceTypePaination,
      count: state.deviceTypePaination.count - 1,
    };
    return {
      ...state,
      deviceTypeArray: deviceTypeslistDataForDelete,
      deviceTypePaination: afterDeleteTypesPagination,
    };
  },

  // Device Models Managment functions.
  GET_MODEL_LIST: (state: State, action: GetModelList): State => {
    const deviceModelListArray = [...action.payload.rows];
    const modelesDataPagination = {
      ...state.deviceModelesPaination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      deviceModelesList: deviceModelListArray,
      deviceModelesPaination: modelesDataPagination,
    };
  },
  ADD_MODEL_DATA: (state: State, action: AddNewMModelData): State => {
    let DeviceModellistDataForAdd = [...state.deviceModelesList];
    if (state.deviceModelesList.length < state.deviceModelesPaination.size) {
      DeviceModellistDataForAdd.push(action.payload);
    }
    let afterCreateModelPagination = {
      ...state.deviceModelesPaination,
      count: state.deviceModelesPaination.count + 1,
    };
    return {
      ...state,
      deviceModelesList: DeviceModellistDataForAdd,
      deviceModelesPaination: afterCreateModelPagination,
    };
  },
  Update_MODEL_LIST_DATA: (state: State, action: UpdateModelData): State => {
    let deviceModelListDataForUpdate = [...state.deviceModelesList];
    let deviceModelListDataForUpdateIndex =
      deviceModelListDataForUpdate.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (deviceModelListDataForUpdateIndex !== -1) {
      deviceModelListDataForUpdate[deviceModelListDataForUpdateIndex] = {
        ...deviceModelListDataForUpdate[deviceModelListDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      deviceModelesList: deviceModelListDataForUpdate,
    };
  },
  DELETE_MODEL_LIST_DATA: (state: State, action: DeleteModelDataset): State => {
    let deviceModellistDataForDelete = [...state.deviceModelesList];
    let deviceModellistDataForDeleteIndex =
      deviceModellistDataForDelete.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (deviceModellistDataForDeleteIndex !== -1) {
      deviceModellistDataForDelete.splice(deviceModellistDataForDeleteIndex, 1);
    }
    let afterDeleteModelPagination = {
      ...state.deviceModelesPaination,
      count: state.deviceModelesPaination.count - 1,
    };
    return {
      ...state,
      deviceModelesList: deviceModellistDataForDelete,
      deviceModelesPaination: afterDeleteModelPagination,
    };
  },

  // Device Firmeware Managment functions.
  GET_FIRMWARE_LIST: (state: State, action: GetFirmWareList): State => {
    const deviceFirmWareListArray = [...action.payload.rows];
    const firmwareDataPagination = {
      ...state.deviceFirmWarePaination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      deviceFirmWareList: deviceFirmWareListArray,
      deviceFirmWarePaination: firmwareDataPagination,
    };
  },
  ADD_FIRMWARE_DATA: (state: State, action: AddNewMFirmWareData): State => {
    let DeviceFirmWarelistDataForAdd = [...state.deviceFirmWareList];
    if (state.deviceFirmWareList.length < state.deviceFirmWarePaination.size) {
      DeviceFirmWarelistDataForAdd.push(action.payload);
    }
    let afterCreateFirmwarePagination = {
      ...state.deviceFirmWarePaination,
      count: state.deviceFirmWarePaination.count + 1,
    };
    return {
      ...state,
      deviceFirmWareList: DeviceFirmWarelistDataForAdd,
      deviceFirmWarePaination: afterCreateFirmwarePagination,
    };
  },
  Update_FIRMWARE_LIST_DATA: (
    state: State,
    action: UpdateFirmWareData
  ): State => {
    let deviceFirmWarelListDataForUpdate = [...state.deviceFirmWareList];
    let deviceFirmWarelListDataForUpdateIndex =
      deviceFirmWarelListDataForUpdate.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (deviceFirmWarelListDataForUpdateIndex !== -1) {
      deviceFirmWarelListDataForUpdate[deviceFirmWarelListDataForUpdateIndex] =
        {
          ...deviceFirmWarelListDataForUpdate[
            deviceFirmWarelListDataForUpdateIndex
          ],
          ...action.payload,
        };
    }
    return {
      ...state,
      deviceFirmWareList: deviceFirmWarelListDataForUpdate,
    };
  },
  DELETE_FIRMWARE_LIST_DATA: (
    state: State,
    action: DeleteFirmWareDataset
  ): State => {
    let deviceFirmWarelistDataForDelete = [...state.deviceFirmWareList];
    let deviceFirmWarelistDataForDeleteIndex =
      deviceFirmWarelistDataForDelete.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (deviceFirmWarelistDataForDeleteIndex !== -1) {
      deviceFirmWarelistDataForDelete.splice(
        deviceFirmWarelistDataForDeleteIndex,
        1
      );
    }
    let afterDeleteFirmWarePagination = {
      ...state.deviceFirmWarePaination,
      count: state.deviceFirmWarePaination.count - 1,
    };
    return {
      ...state,
      deviceFirmWareList: deviceFirmWarelistDataForDelete,
      deviceFirmWarePaination: afterDeleteFirmWarePagination,
    };
  },
  //  Shedule Listing
  GET_SHEDULE_LIST: (state: State, action: GetSheduleListList): State => {
    const deviceSheduleListArray = [...action.payload.rows];
    const sheduleDataPagination = {
      ...state.sheduleDevicesListPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      sheduleDevicesList: deviceSheduleListArray,
      sheduleDevicesListPagination: sheduleDataPagination,
    };
  },
  Update_SHEDULE_LIST_DATA: (
    state: State,
    action: UpdateSheduleListData
  ): State => {
    let deviceShedulelListDataForUpdate = [...state.sheduleDevicesList];
    let deviceShedulelListDataForUpdateIndex =
      deviceShedulelListDataForUpdate.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (deviceShedulelListDataForUpdateIndex !== -1) {
      deviceShedulelListDataForUpdate[deviceShedulelListDataForUpdateIndex] = {
        ...deviceShedulelListDataForUpdate[
          deviceShedulelListDataForUpdateIndex
        ],
        ...action.payload,
      };
    }
    return {
      ...state,
      sheduleDevicesList: deviceShedulelListDataForUpdate,
    };
  },
  SET_USER_FIRMWARE_UPDATE: (
    state: State,
    action: userfirmwareUpdateList
  ): State => {
    const firmwaeArrayForUpdateArray = [...action.payload];
    return {
      ...state,
      firmwaeArrayForUpdate: firmwaeArrayForUpdateArray,
    };
  },

  UPDATE_USER_FIRMWARE_UPDATE: (
    state: State,
    action: updateUserFirmwareUpdateListData
  ): State => {
    let deviceFirmwareListDataForUpdate = [...state.firmwaeArrayForUpdate];

    let deviceFirmwareListDataForUpdateIndex =
      deviceFirmwareListDataForUpdate.findIndex((data) => {
        return data.id === action.payload.id;
      });

    if (deviceFirmwareListDataForUpdateIndex !== -1) {
      deviceFirmwareListDataForUpdate[deviceFirmwareListDataForUpdateIndex] = {
        ...deviceFirmwareListDataForUpdate[
          deviceFirmwareListDataForUpdateIndex
        ],
        ...action.payload,
      };
    }
    return {
      ...state,
      firmwaeArrayForUpdate: deviceFirmwareListDataForUpdate,
    };
  },
  REMOVE_USER_FIRMWARE_UPDATE: (
    state: State,
    action: RemoveUserFirmwareUpdateListData
  ): State => {
    let deviceUpdateFirmWarelistDataForDelete = [
      ...state.firmwaeArrayForUpdate,
    ];
    let deviceUpdateFirmWarelistDataForDeleteIndex =
      deviceUpdateFirmWarelistDataForDelete.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (deviceUpdateFirmWarelistDataForDeleteIndex !== -1) {
      deviceUpdateFirmWarelistDataForDelete.splice(
        deviceUpdateFirmWarelistDataForDeleteIndex,
        1
      );
    }
    return {
      ...state,
      firmwaeArrayForUpdate: deviceUpdateFirmWarelistDataForDelete,
    };
  },
  SET_SELECTED_FIRMWARE_DATA: (
    state: State,
    action: SetSelectedFirmwareData
  ): State => {
    let selectedDataFirmware = { ...action.payload };
    return {
      ...state,
      selectedFirmWare: selectedDataFirmware,
    };
  },
};
interface DeviceProvideProps {
  children: ReactNode;
}
const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
export const DeviceProvider: FC<DeviceProvideProps> = (props) => {
  const { isAuthenticated } = useAuth();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  // Device Motor Managment functions.
  const setMortorListData = async (payload) => {
    // console.log("setMortorListData call");
    dispatch({ type: "GET_MOTOR_LIST", payload });
  };
  const addMoterDataSet = async (payload) => {
    dispatch({ type: "ADD_MOTOR_LIST_DATA", payload });
  };
  const udpateDataSet = async (payload) => {
    dispatch({ type: "Update_MOTOR_LIST_DATA", payload });
  };
  const removeDatafromSet = async (payload) => {
    dispatch({ type: "DELETE_MOTOR_LIST_DATA", payload });
  };

  // Device Configration Managment functions.
  const setConfigListData = async (payload) => {
    // console.log("setMortorListData call");
    dispatch({ type: "GET_CONFIGRATION_LIST", payload });
  };
  const addConfigDataSet = async (payload) => {
    dispatch({ type: "ADD_CONFIGRATION_DATA", payload });
  };
  const udpateConfigDataSet = async (payload) => {
    dispatch({ type: "Update_CONFIGRATION_LIST_DATA", payload });
  };
  const removeConfigDatafromSet = async (payload) => {
    dispatch({ type: "DELETE_CONFIGRATION_LIST_DATA", payload });
  };

  // Device Type Managment functions.
  const setTypesListData = async (payload) => {
    // console.log("setMortorListData call");
    dispatch({ type: "GET_TYPES_LIST", payload });
  };
  const addTypesDataSet = async (payload) => {
    dispatch({ type: "ADD_TYPES_DATA", payload });
  };
  const udpateTypesDataSet = async (payload) => {
    dispatch({ type: "Update_TYPES_LIST_DATA", payload });
  };
  const removeTypesDatafromSet = async (payload) => {
    dispatch({ type: "DELETE_TYPES_LIST_DATA", payload });
  };

  // Device Model Managment functions.
  const setModelListData = async (payload) => {
    // console.log("setMortorListData call");
    dispatch({ type: "GET_MODEL_LIST", payload });
  };
  const addModelDataSet = async (payload) => {
    dispatch({ type: "ADD_MODEL_DATA", payload });
  };
  const udpateModelDataSet = async (payload) => {
    dispatch({ type: "Update_MODEL_LIST_DATA", payload });
  };
  const removeModelDatafromSet = async (payload) => {
    dispatch({ type: "DELETE_MODEL_LIST_DATA", payload });
  };

  // Device FirmWare Managment functions.
  const setFirmWareListData = async (payload) => {
    // console.log("setMortorListData call");
    dispatch({ type: "GET_FIRMWARE_LIST", payload });
  };
  const addFirmWareDataSet = async (payload) => {
    dispatch({ type: "ADD_FIRMWARE_DATA", payload });
  };
  const udpateFirmWareDataSet = async (payload) => {
    dispatch({ type: "Update_FIRMWARE_LIST_DATA", payload });
  };
  const removeFirmWareDatafromSet = async (payload) => {
    dispatch({ type: "DELETE_FIRMWARE_LIST_DATA", payload });
  };
  const setSheduleListData = async (payload) => {
    dispatch({ type: "GET_SHEDULE_LIST", payload });
  };
  const udpateSheduleDataSet = async (payload) => {
    dispatch({ type: "Update_SHEDULE_LIST_DATA", payload });
  };

  const updatedListOfFirmware = async (payload) => {
    dispatch({ type: "SET_USER_FIRMWARE_UPDATE", payload });
  };
  const updateListofFirmwareData = async (payload) => {
    dispatch({ type: "UPDATE_USER_FIRMWARE_UPDATE", payload });
  };
  const removerListDataOfFirmware = async (payload) => {
    dispatch({ type: "REMOVE_USER_FIRMWARE_UPDATE", payload });
  };
  const setSelectedDatatOfFirmWae = async (payload) => {
    dispatch({ type: "SET_SELECTED_FIRMWARE_DATA", payload });
  };
  return (
    <DeviceContext.Provider
      value={{
        ...state,

        setMortorListData,
        addMoterDataSet,
        udpateDataSet,
        removeDatafromSet,

        setConfigListData,
        addConfigDataSet,
        udpateConfigDataSet,
        removeConfigDatafromSet,

        setTypesListData,
        addTypesDataSet,
        udpateTypesDataSet,
        removeTypesDatafromSet,

        setModelListData,
        addModelDataSet,
        udpateModelDataSet,
        removeModelDatafromSet,

        removeFirmWareDatafromSet,
        udpateFirmWareDataSet,
        addFirmWareDataSet,
        setFirmWareListData,

        setSheduleListData,
        udpateSheduleDataSet,

        updatedListOfFirmware,
        updateListofFirmwareData,
        removerListDataOfFirmware,
        setSelectedDatatOfFirmWae,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
DeviceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DeviceContext;
