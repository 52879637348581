import React, { createContext, useContext, useEffect, useState } from "react";
import { Client, IOnMessageArrived, IOnConnectionLost } from "paho-mqtt";
import { getClientIdFromCookie } from "utils/general";
import toast from "react-hot-toast";

// destinationName: "SPECK_7C98D0/Info";
// duplicate: false;
// payloadBytes: Uint8Array(20);
// payloadString: "Badu Turbo Pro 1.0.0";
// qos: 0;
// retained: true;
// topic: "SPECK_7C98D0/Info";

export interface mqttMessage {
  destinationName?: string;
  duplicate?: boolean;
  payloadBytes?: any;
  payloadString?: string;
  qos?: number;
  retained?: boolean;
  topic?: string;
  [key: string]: any;
}

// Define MQTT context types
interface MqttContextType {
  client: Client | null;
  connect: () => void;
  disconnect: () => void;
  mqttMessages: Array<mqttMessage>;
  latestMqttMessage: mqttMessage;
}

const MqttContext = createContext<MqttContextType | undefined>(undefined);

// MQTT Provider component
export const MqttProvider: React.FC = ({ children }) => {
  const [client, setClient] = useState<Client | null>(null);
  const [mqttMessages, setMqttMessages] = useState<Array<mqttMessage>>([]);
  const [latestMqttMessage, setLatestMqttMessage] = useState<mqttMessage>();

  const clientId = `speck-pump-${Date.now()}`;

  useEffect(() => {
    if (process.env.REACT_APP_REGION === "us") {
      try {
        const mqttClient = new Client(
          process.env.REACT_APP_V1_MQTT_BASE_URL,
          clientId
        );
        setClient(mqttClient);

        // Connect to MQTT broker
        const connectOptions = {
          onSuccess: () => {
            // Subscribe to desired topics
            // mqttClient.subscribe(`${clientId}/Version`);
            // mqttClient.subscribe(`${clientId}/Info`);
            // console.warn("MQTT connected!");
            // toast.success("MQTT connected!");
          },
          onFailure: (error) => {
            console.error("Failed to connect to MQTT broker:", error);
          },
          userName: process.env.REACT_APP_V1_MQTT_USERNAME,
          password: process.env.REACT_APP_V1_MQTT_PASSWORD,
        };

        mqttClient.connect(connectOptions);

        // Define your onMessageArrived and onConnectionLost handlers here
        const onMessageArrived: IOnMessageArrived = (message) => {
          // Handle the received message
          setLatestMqttMessage(message);

          setMqttMessages((prev) => {
            return [...prev, message];
          });
        };

        const onConnectionLost: IOnConnectionLost = (responseObject) => {
          if (responseObject.errorCode !== 0) {
            console.error("Connection lost:", responseObject.errorMessage);

            // toast.error("Connection with MQTT has been lost!");

            mqttClient.connect(connectOptions);
          }
        };

        // Set callback handlers
        mqttClient.onMessageArrived = onMessageArrived;
        mqttClient.onConnectionLost = onConnectionLost;

        return () => {
          if (mqttClient.isConnected()) {
            mqttClient.disconnect();
          }
        };
      } catch (error) {
        throw new Error(error);
      }
    }
  }, []);

  const connect = () => {
    if (client && !client.isConnected()) {
      client.connect();
    }
  };

  const disconnect = () => {
    if (client && client.isConnected()) {
      client.disconnect();
    }
  };

  return (
    <MqttContext.Provider
      value={{ client, connect, disconnect, mqttMessages, latestMqttMessage }}
    >
      {children}
    </MqttContext.Provider>
  );
};

// Custom hook for using MQTT context
export const useMqtt = () => {
  const context = useContext(MqttContext);
  if (!context) {
    throw new Error("useMqtt must be used within an MqttProvider");
  }
  return context;
};
