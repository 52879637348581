import { useAuth } from 'hooks';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface UserGuardProps {
  children: ReactNode;
}

const UserGuard: FC<UserGuardProps> = ({ children }) => {
  const auth = useAuth();

  if (auth.isAuthenticated) {
    return <Navigate to="/" />;
  } return <>{children}</>;
};

UserGuard.propTypes = {
  children: PropTypes.node
};

export default UserGuard;
