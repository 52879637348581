import { ContentWrapper } from "container/ApplictaionContent";
import LoginComp from "container/Authentication/Login";
import gtm from "lib/gtm";
import type { FC } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Login: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <ContentWrapper pageTitle={t("Login")} smallContent>
      <LoginComp />
    </ContentWrapper>
  );
};

export default Login;
