import React from "react";
import { CMSProvider } from "../contexts/CMSManagment";
import { DeviceProvider } from "../contexts/DeviceManagment";
import { AuthProvider } from "../contexts/JWTContext";
import { UserProvider } from "../contexts/UserContext";
import { UserDeviceProvider } from "../contexts/UserDeviceManagment";
import { UserDataProvide } from "../contexts/UserDataManagment";
import { SocketProvider } from "../contexts/Socket/context";
const Store = (props) => {
  return (
    <>
      <AuthProvider>
        <UserDataProvide>
          <UserProvider>
            <DeviceProvider>
              <UserDeviceProvider>
                <SocketProvider>
                  <CMSProvider>{props.children}</CMSProvider>
                </SocketProvider>
              </UserDeviceProvider>
            </DeviceProvider>
          </UserProvider>
        </UserDataProvide>
      </AuthProvider>
    </>
  );
};

export default Store;
