import { Grid, Divider } from "@material-ui/core";
import Breadcrumb from "components/Common/BreadCrumb";
import { LargeGrid, SmallGrid } from "components/Form/Grid";
import ContentHeaderLeft from "components/Header/ContentHeaderLeft";
import ContentHeaderRight from "components/Header/ContentHeaderRight";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";

interface WraperProps {
  pageTitle: string;
  isCancelBtn?: boolean;
  cancelTo?: string;
  NoBreadCrumb?: boolean;
  AddButton?: {
    Button?: boolean;
    BtnLable?: string;
    BtnLink?: string;
    BtnAccess?: Array<number>;
    BtnComponent?: ReactNode;
    [key: string]: any;
  };
  sx?: {
    [key: string]: any;
  };
}

const ContentHeader: FC<WraperProps> = ({
  pageTitle,
  AddButton,
  isCancelBtn,
  cancelTo,
  NoBreadCrumb,
  sx,
}) => (
  <>
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      sx={{ mb: 1, ...sx }}
    >
      <LargeGrid>
        <ContentHeaderLeft pageTitle={pageTitle}>
          {NoBreadCrumb ? null : <Breadcrumb />}
        </ContentHeaderLeft>
      </LargeGrid>
      <SmallGrid>
        <ContentHeaderRight
          isCancelBtn={isCancelBtn}
          cancelTo={cancelTo}
          AddButton={AddButton}
        />
      </SmallGrid>
    </Grid>
    <Divider sx={{ mb: 3 }} />
  </>
);

ContentHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  isCancelBtn: PropTypes.bool,
  cancelTo: PropTypes.string,
  NoBreadCrumb: PropTypes.bool,
  AddButton: PropTypes.shape({
    Button: PropTypes.bool,
    BtnLable: PropTypes.string,
    BtnLink: PropTypes.string,
    BtnAccess: PropTypes.array,
    BtnComponent: PropTypes.node,
  }),
  sx: PropTypes.shape({}),
};

export default ContentHeader;
