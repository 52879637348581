import { Box, TextField, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ToolTipFieldProps {
  children: any;
  [key: string]: any;
  title?: string;
}

const ToolTipField: FC<ToolTipFieldProps> = ({ title, children, ...other }) => {
  return (
    <Box sx={{ cursor: "pointer" }}>
      <Tooltip title={title ?? ""} {...other}>
        {children}
      </Tooltip>
    </Box>
  );
};
export default ToolTipField;
