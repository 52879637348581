import type { AppBarProps } from "@material-ui/core";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../Logo";
import AccountPopover from "./AccountPopover";
import { useUser } from "hooks";

const REGION = process.env.REACT_APP_REGION;

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: "#fff",
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
    color: theme.palette.primary.contrastText,
    padding: "0.8rem 0 0.8rem 0",
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  const { user } = useUser();

  const isAdmin = user?.profile?.role_id === 1 || user?.profile?.role_id === 2;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 80, justifyContent: "space-between" }}>
        {/* <Box> */}
        <IconButton
          color="primary"
          onClick={onSidebarMobileOpen}
          sx={{
            visibility: {
              lg: "hidden",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            height={70}
            width={200}
            // variation="whiteLogo"
          />
        </RouterLink>
        {/* </Box> */}
        <Box sx={{ ml: 2, display: "flex" }}>
          {isAdmin && REGION === "us" && (
            <Button
              sx={{ mx: 2 }}
              variant="outlined"
              size="small"
              onClick={() => {
                const url = `https://${process.env.REACT_APP_V1_BASE_URL}/app/dashboards/default`;
                window.open(url, "_self", "noopener,noreferrer");
              }}
            >
              Switch to V1
            </Button>
          )}
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
