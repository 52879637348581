import Cookies from "js-cookie";
import { Languages } from "types/languages";
import addMinutes from "./addTime";

const DOMAIN = process.env.REACT_APP_DOMAIN_NAME || "baduconnect.com";

export const readCookie = () => {
  const token = Cookies.get("token");
  const rToken = Cookies.get("refreshToken");
  const sessionTime = Cookies.get("sessionTime");

  const x_access_token = Cookies.get("x_access_token");
  const v1_user_id = Cookies.get("v1_user_id");

  return [
    token || "",
    rToken || "",
    sessionTime || "",
    x_access_token || "",
    v1_user_id || "",
  ];
};

export const getClientIdFromCookie = () => {
  const client_id = Cookies.get("client_id");

  return client_id || "";
};

export const setCookie = (
  token: string,
  refreshToken: string,
  x_access_token: string,
  sessionTime?: string | number | any,
  v1_user_id?: string,
  userData?: any
) => {

  Cookies.set("refreshToken", refreshToken, {
    path: "/",
    domain: DOMAIN,
  });
  Cookies.set("token", token, {
    path: "/",
    domain: DOMAIN,
  });

  if (sessionTime) {
    Cookies.set("sessionTime", sessionTime);
  }
  if (x_access_token) {
    Cookies.set("x_access_token", x_access_token, {
      path: "/",
      domain: DOMAIN,
    });

    if (userData?.email) {
      Cookies.set("email", userData?.email, {
        path: "/",
        domain: DOMAIN,
      });
    }

    if (userData?.roleId) {
      Cookies.set("role", userData?.roleId, {
        path: "/",
        domain: DOMAIN,
      });
    }
  }
  if (v1_user_id) {
    Cookies.set("v1_user_id", v1_user_id);
  }
};

export const setClientIdIntoCookie = (clientId: string) => {
  Cookies.set("client_id", clientId);
};

export const getDeviceIdFromCookie = () => {
  const device_id = Cookies.get("device_id");
  return device_id;
};

export const getV1UserIdFromCookie = () => {
  const v1_user_id = Cookies.get("v1_user_id");
  return v1_user_id;
};

export const clearStorage = () => {
  Cookies.remove("token", {
    path: "/",
    domain: DOMAIN,
  });
  Cookies.remove("refreshToken", {
    path: "/",
    domain: DOMAIN,
  });
  Cookies.remove("sessionTime");
  Cookies.remove("hour_format");
  Cookies.remove("x_access_token", {
    path: "/",
    domain: DOMAIN,
  });
  Cookies.remove("email", {
    path: "/",
    domain: DOMAIN,
  });
  Cookies.remove("role", {
    path: "/",
    domain: DOMAIN,
  });

  Cookies.remove("v1_user_id");
  Cookies.remove("client_id");
};

export const truncateString = (string: string, limit: number) => {
  if (string.length > limit) {
    return `${string.substring(0, limit)}...`;
  }
  return string;
};

export const savedDeviceId = (id) => {
  Cookies.set("device_id", id);
};

export const getDeviceId = () => {
  return Cookies.get("device_id");
};
export const setCountryCode = (data) => {
  Cookies.set("country", data);
};
export const getCountryCode = () => {
  return Cookies.get("country");
};
export const saveLanguage = (language: Languages): void => {
  window.localStorage.setItem("language", language);
};

export const selectedLanguage = (): Languages =>
  window.localStorage.getItem("language");

export const getCookieByKey = (key: string) => {
  const data = Cookies.get(key);
  return data;
};
