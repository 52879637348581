// fullWidth: true
// fullScreen: false
// maxWidth:"sm"
// openModal
// closeModal
// @ts-nocheck
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useReducer } from 'react';

interface State {
  fullWidth: boolean,
  fullScreen: boolean,
  maxWidth: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false,
  isModalOpen: boolean,
  transitionDuration: number,
  direction: 'left' | 'right' | 'up' | 'down',
  modelContent: ReactNode,
  nested: {
    fullWidth: boolean,
    fullScreen: boolean,
    maxWidth: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false,
    isModalOpen: boolean,
    transitionDuration: number,
    direction: 'left' | 'right' | 'up' | 'down',
    modelContent: ReactNode,
  }
}

const initialState = {
  fullWidth: true,
  fullScreen: false,
  maxWidth: 'sm',
  isModalOpen: false,
  transitionDuration: 1000,
  direction: 'left',
  modelContent: <></>,
  nested: {
    fullWidth: true,
    fullScreen: false,
    maxWidth: 'sm',
    isModalOpen: false,
    transitionDuration: 1000,
    direction: 'left',
    modelContent: <></>,
  }
};

interface UtilContextValue extends State {
  openModal: () => void;
  closeModal: () => void;
  // changeNestedModelParams: () => void;
  changeModelBody: (val: ReactNode) => void;
  openNestedModal: () => void;
  closeNestedModal: () => void;
  changeNestedModelContent: (val: any) => void;
}

const UtilContext = createContext<UtilContextValue>({
  ...initialState,
  openModal: () => Promise.resolve(),
  closeModal: () => Promise.resolve(),
  changeModelBody: () => Promise.resolve(),
  openNestedModal: () => Promise.resolve(),
  closeNestedModal: () => Promise.resolve(),
  changeNestedModelContent: () => Promise.resolve(),
});

interface UtilProviderProps {
  children: ReactNode;
}

type Open = {
  type: 'OPEN'
};

type OpenNested = {
  type: 'OPEN_NESTED'
};

type ChangeBody = {
  type: 'CHANGE_BODY',
  payload: ReactNode
};

type ChangeNestedModelContent = {
  type: 'CHANGE_NESTED_MODEL_CONTENT',
  payload: any
};

type Close = {
  type: 'CLOSE';
};

type CloseNested = {
  type: 'CLOSE_NESTED'
};

type Action =
  | Open
  | Close
  | ChangeBody
  | OpenNested
  | CloseNested
  | ChangeNestedModelContent;

const handlers: Record<string, (state: State, action: Action) => State> = {
  OPEN: (state: State): State => ({
    ...state,
    isModalOpen: true
  }),
  CLOSE: (state: State): State => ({
    ...state,
    isModalOpen: false
  }),
  CHANGE_BODY: (state: State, action: ChangeBody) => ({
    ...state,
    modelContent: action && action.payload
  }),
  OPEN_NESTED: (state: State): State => ({
    ...state,
    nested: {
      ...state.nested,
      isModalOpen: true
    }
  }),
  CLOSE_NESTED: (state: State): State => ({
    ...state,
    nested: {
      ...state.nested,
      isModalOpen: false
    }
  }),

  CHANGE_NESTED_MODEL_CONTENT: (state: State, action: ChangeNestedModelContent) => {
    // console.log('HERE', action);
    return ({
      ...state,
      nested: {
        ...state.nested,
        ...action.payload
      }
    });
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export const UtilProvider: FC<UtilProviderProps> = (props) => {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = () => {
    dispatch({ type: 'OPEN' });
  };

  const openNestedModal = () => {
    dispatch({ type: 'OPEN_NESTED' });
  };

  const closeModal = () => {
    dispatch({ type: 'CLOSE' });
  };

  const closeNestedModal = () => {
    dispatch({ type: 'CLOSE_NESTED' });
  };

  const changeModelBody = (content: ReactNode) => {
    dispatch({ type: 'CHANGE_BODY', payload: content });
  };

  const changeNestedModelContent = (content: any) => {
    dispatch({ type: 'CHANGE_NESTED_MODEL_CONTENT', payload: content });
  };

  return (
    <UtilContext.Provider
      value={{
        ...state,
        openModal,
        closeModal,
        changeModelBody,
        openNestedModal,
        closeNestedModal,
        changeNestedModelContent
      }}
    >
      {children}
    </UtilContext.Provider>
  );
};

UtilProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UtilContext;
