import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useReducer } from "react";
import { useAuth } from "../hooks";
import {
  Action,
  AddNewMCmsDashBoardData,
  AddNewMCmsHelpLinksData,
  AddNewMCmsInfoData,
  AddNewMCmsLedCodeData,
  AddNewMCmsTutorialData,
  DeleteCmsHelpLinksDataset,
  DeleteCmsInfoDataset,
  DeleteCmsLedCodeDataset,
  DeleteCmsTutorialDataset,
  GetDashBoardList,
  GetHelpLinksList,
  GetInfoList,
  GetLedCodeList,
  GetTOCList,
  GetTutorialList,
  UpdateCmsDashBoardData,
  UpdateCmsHelpLinksData,
  UpdateCmsInfoData,
  UpdateCmsLedCodeData,
  UpdateCmsTOCData,
  UpdateCmsTutorialData,
  GetNotificationList,
  AddNewMCmsNotificationData,
  DeleteCmsNotificationDataset,
  UpdateCmsNotificationData,
  DeletFeedBackDataset,
  UpdatFeedBackData,
  AddNewFeedBackData,
  GetFeedBackList,
  ActivityLogSet,
  AdminActivityLogSet,
  SubAdminActivityLogSet,
} from "./Interface/CMSManagment/CMSManagmentActions";
import {
  CMSContextValue,
  State,
} from "./Interface/CMSManagment/CMSManagmentInterface";
const initialState: State = {
  infoTagsList: [],
  infoTagsPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  ledCodeList: [],
  ledCodePagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  tutorialList: [],
  tutorialPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  tocContent: {
    id: 0,
    short_code: "",
    is_active: true,
    title_en: "",
    content_en: "",
    title_es: "",
    content_es: "",
    title_fr: "",
    content_fr: "",
    title_de: "",
    content_de: "",
    last_approval_date: null,
    createdAt: "",
  },
  helpsLinkArray: [],
  helpLinksPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  dashbordLinkArray: [],
  dashbordLinksPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  notificationList: [],
  notificationPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  feedBackList: [],
  feedBackPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  activityLogList: [],
  activityLogPagintion: {
    count: 0,
    page: 0,
    size: 10,
  },
  adminActivityLogList: [],
  adminActivityLogPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  subAdminActivityList: [],
  subAdminActivityLogPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
};

const CMSContext = createContext<CMSContextValue>({
  ...initialState,
  setInforTagListData: () => Promise.resolve(),
  addInforTagDataSet: () => Promise.resolve(),
  udpateInforTagDataSet: () => Promise.resolve(),
  removeInforTagDataSet: () => Promise.resolve(),

  setLedCodeListData: () => Promise.resolve(),
  addLedCodeDataSet: () => Promise.resolve(),
  udpateLedCodeDataSet: () => Promise.resolve(),
  removeLedCodeDataSet: () => Promise.resolve(),

  setTutorialListData: () => Promise.resolve(),
  addTutorialDataSet: () => Promise.resolve(),
  udpateTutorialDataSet: () => Promise.resolve(),
  removeTutorialDataSet: () => Promise.resolve(),

  setTOCData: () => Promise.resolve(),
  udpateTOCDataSet: () => Promise.resolve(),

  setHelpLinksListData: () => Promise.resolve(),
  addHelpLinksDataSet: () => Promise.resolve(),
  udpateHelpLinksDataSet: () => Promise.resolve(),
  removeHelpLinksDataSet: () => Promise.resolve(),

  setDashbordLinksListData: () => Promise.resolve(),
  addDashbordLinksDataSet: () => Promise.resolve(),
  udpateDashbordLinksDataSet: () => Promise.resolve(),
  removeDashbordLinksDataSet: () => Promise.resolve(),

  setNotificationListData: () => Promise.resolve(),
  addNotificationDataSet: () => Promise.resolve(),
  udpateNotificationDataSet: () => Promise.resolve(),
  removeNotificationDataSet: () => Promise.resolve(),

  setFeedBackListData: () => Promise.resolve(),
  addFeedBackDataSet: () => Promise.resolve(),
  udpateFeedBackDataSet: () => Promise.resolve(),
  removeFeedBackDataSet: () => Promise.resolve(),

  setActivityLog: () => Promise.resolve(),
  setAdminActivityLog: () => Promise.resolve(),
  setAubAdminActivityLog: () => Promise.resolve(),
});

const handlers: Record<string, (state: State, action: Action) => State> = {
  //  CMS Info Action Interface
  GET_Info_LIST: (state: State, action: GetInfoList): State => {
    const infoTagListArray = [...action.payload.rows];
    const infoPagination = {
      ...state.infoTagsPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      infoTagsList: infoTagListArray,
      infoTagsPagination: infoPagination,
    };
  },
  ADD_CMS_INFO_DATA: (state: State, action: AddNewMCmsInfoData): State => {
    let cmsInfolistDataForAdd = [...state.infoTagsList];
    if (state.infoTagsList.length < state.infoTagsPagination.size) {
      cmsInfolistDataForAdd.push(action.payload);
    }
    let afterCreateInfotagPagination = {
      ...state.infoTagsPagination,
      count: state.infoTagsPagination.count + 1,
    };
    return {
      ...state,
      infoTagsList: cmsInfolistDataForAdd,
      infoTagsPagination: afterCreateInfotagPagination,
    };
  },
  Update_CMS_INFO_LIST_DATA: (
    state: State,
    action: UpdateCmsInfoData
  ): State => {
    let cmsInfolListDataForUpdate = [...state.infoTagsList];
    let cmsInfolListDataForUpdateIndex = cmsInfolListDataForUpdate.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (cmsInfolListDataForUpdateIndex !== -1) {
      cmsInfolListDataForUpdate[cmsInfolListDataForUpdateIndex] = {
        ...cmsInfolListDataForUpdate[cmsInfolListDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      infoTagsList: cmsInfolListDataForUpdate,
    };
  },
  DELETE_CMS_INFO_LIST_DATA: (
    state: State,
    action: DeleteCmsInfoDataset
  ): State => {
    let cmsInfoListlistDataForDelete = [...state.infoTagsList];
    let cmsInfoListlistDataForDeleteIndex =
      cmsInfoListlistDataForDelete.findIndex((data) => {
        return data.id === action.payload.id;
      });
    if (cmsInfoListlistDataForDeleteIndex !== -1) {
      cmsInfoListlistDataForDelete.splice(cmsInfoListlistDataForDeleteIndex, 1);
    }
    let afterDeleteInfotagPagination = {
      ...state.infoTagsPagination,
      count: state.infoTagsPagination.count - 1,
    };
    return {
      ...state,
      infoTagsList: cmsInfoListlistDataForDelete,
      infoTagsPagination: afterDeleteInfotagPagination,
    };
  },

  //  CMS LED Action Interface
  GET_LED_LIST: (state: State, action: GetLedCodeList): State => {
    const ledCodeListArray = [...action.payload.rows];
    const ledCodePagination = {
      ...state.ledCodePagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      ledCodeList: ledCodeListArray,
      ledCodePagination: ledCodePagination,
    };
  },
  ADD_CMS_LED_DATA: (state: State, action: AddNewMCmsLedCodeData): State => {
    let cmsLedCodeDataForAdd = [...state.ledCodeList];
    if (state.ledCodeList.length < state.ledCodePagination.size) {
      cmsLedCodeDataForAdd.push(action.payload);
    }
    let afterCreateLedPagination = {
      ...state.ledCodePagination,
      count: state.ledCodePagination.count + 1,
    };
    return {
      ...state,
      // ledCodeList: cmsLedCodeDataForAdd,
      ledCodePagination: afterCreateLedPagination,
    };
  },
  Update_CMS_LED_LIST_DATA: (
    state: State,
    action: UpdateCmsLedCodeData
  ): State => {
    let cmsLedCodeDataForUpdate = [...state.ledCodeList];
    let cmsLedCodeDataForUpdateIndex = cmsLedCodeDataForUpdate.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (cmsLedCodeDataForUpdateIndex !== -1) {
      cmsLedCodeDataForUpdate[cmsLedCodeDataForUpdateIndex] = {
        ...cmsLedCodeDataForUpdate[cmsLedCodeDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      ledCodeList: cmsLedCodeDataForUpdate,
    };
  },
  DELETE_CMS_LED_LIST_DATA: (
    state: State,
    action: DeleteCmsLedCodeDataset
  ): State => {
    let cmsLedCodeDataForDelete = [...state.ledCodeList];
    let cmsLedCodeDataForDeleteIndex = cmsLedCodeDataForDelete.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (cmsLedCodeDataForDeleteIndex !== -1) {
      cmsLedCodeDataForDelete.splice(cmsLedCodeDataForDeleteIndex, 1);
    }
    let afterDeletLedPagination = {
      ...state.ledCodePagination,
      count: state.ledCodePagination.count - 1,
    };
    return {
      ...state,
      ledCodeList: cmsLedCodeDataForDelete,
      ledCodePagination: afterDeletLedPagination,
    };
  },

  //  CMS Tutorial Action Interface
  GET_CMS_TUTORIAL__LIST: (state: State, action: GetTutorialList): State => {
    const tutorialListArray = [...action.payload.rows];
    const tutorialPagination = {
      ...state.tutorialPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      tutorialList: tutorialListArray,
      tutorialPagination: tutorialPagination,
    };
  },
  ADD_CMS_TUTORIAL_DATA: (
    state: State,
    action: AddNewMCmsTutorialData
  ): State => {
    let cmsTutorialDataForAdd = [...state.tutorialList];
    if (state.tutorialList.length < state.tutorialPagination.size) {
      cmsTutorialDataForAdd.push(action.payload);
    }
    let afterCreateTutorialPagination = {
      ...state.tutorialPagination,
      count: state.tutorialPagination.count + 1,
    };
    return {
      ...state,
      tutorialList: cmsTutorialDataForAdd,
      tutorialPagination: afterCreateTutorialPagination,
    };
  },
  Update_CMS_TUTORIAL_LIST_DATA: (
    state: State,
    action: UpdateCmsTutorialData
  ): State => {
    let cmsTutorialDataForUpdate = [...state.tutorialList];
    let cmsTutorialDataForUpdateIndex = cmsTutorialDataForUpdate.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );

    action.payload.status = +action.payload.status;

    if (cmsTutorialDataForUpdateIndex !== -1) {
      cmsTutorialDataForUpdate[cmsTutorialDataForUpdateIndex] = {
        ...cmsTutorialDataForUpdate[cmsTutorialDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      tutorialList: cmsTutorialDataForUpdate,
    };
  },
  DELETE_CMS_TUTORIAL_LIST_DATA: (
    state: State,
    action: DeleteCmsTutorialDataset
  ): State => {
    let cmsTutorialDataForDelete = [...state.tutorialList];
    let cmsTutorialDataForDeleteIndex = cmsTutorialDataForDelete.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (cmsTutorialDataForDeleteIndex !== -1) {
      cmsTutorialDataForDelete.splice(cmsTutorialDataForDeleteIndex, 1);
    }
    let afterDeletTutorialPagination = {
      ...state.tutorialPagination,
      count: state.tutorialPagination.count - 1,
    };
    return {
      ...state,
      tutorialList: cmsTutorialDataForDelete,
      tutorialPagination: afterDeletTutorialPagination,
    };
  },

  // TOC
  GET_CMS_TOC__LIST: (state: State, action: GetTOCList): State => {
    const TocData = { ...state.tocContent, ...action.payload };
    return {
      ...state,
      tocContent: TocData,
    };
  },
  Update_CMS_TOC_LIST_DATA: (state: State, action: UpdateCmsTOCData): State => {
    const TocData = { ...state.tocContent, ...action.payload };
    return {
      ...state,
      tocContent: TocData,
    };
  },

  // Help Links Update
  GET_CMS_HELP_LINKS__LIST: (state: State, action: GetHelpLinksList): State => {
    const helpLinksListArray = [...action.payload.rows];
    const helpLinksPagination = {
      ...state.helpLinksPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      helpsLinkArray: helpLinksListArray,
      helpLinksPagination: helpLinksPagination,
    };
  },
  ADD_CMS_HELP_LINKS_DATA: (
    state: State,
    action: AddNewMCmsHelpLinksData
  ): State => {
    let cmsHelpLinksForAdd = [...state.helpsLinkArray];
    if (state.helpsLinkArray.length < state.helpLinksPagination.size) {
      cmsHelpLinksForAdd.push(action.payload);
    }
    let afterCreateHelpsLinkPagination = {
      ...state.helpLinksPagination,
      count: state.helpLinksPagination.count + 1,
    };
    return {
      ...state,
      helpsLinkArray: cmsHelpLinksForAdd,
      helpLinksPagination: afterCreateHelpsLinkPagination,
    };
  },
  Update_CMS_HELP_LINKS_LIST_DATA: (
    state: State,
    action: UpdateCmsHelpLinksData
  ): State => {
    let cmsHelpLiksForUpdate = [...state.helpsLinkArray];
    let cmsHelpLiksForUpdateIndex = cmsHelpLiksForUpdate.findIndex((data) => {
      return data.id === action.payload.id;
    });
    if (cmsHelpLiksForUpdateIndex !== -1) {
      cmsHelpLiksForUpdate[cmsHelpLiksForUpdateIndex] = {
        ...cmsHelpLiksForUpdate[cmsHelpLiksForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      helpsLinkArray: cmsHelpLiksForUpdate,
    };
  },
  DELETE_CMS_HELP_LINKS_LIST_DATA: (
    state: State,
    action: DeleteCmsHelpLinksDataset
  ): State => {
    let cmsHelpLinksForDelete = [...state.helpsLinkArray];
    let cmsHelpLinksForDeleteIndex = cmsHelpLinksForDelete.findIndex((data) => {
      return data.id === action.payload.id;
    });
    if (cmsHelpLinksForDeleteIndex !== -1) {
      cmsHelpLinksForDelete.splice(cmsHelpLinksForDeleteIndex, 1);
    }
    let afterDeletHelpLinksPagination = {
      ...state.helpLinksPagination,
      count: state.helpLinksPagination.count - 1,
    };
    return {
      ...state,
      helpsLinkArray: cmsHelpLinksForDelete,
      helpLinksPagination: afterDeletHelpLinksPagination,
    };
  },

  // Dash board Links Update
  GET_CMS_DASHBORD_LINKS__LIST: (
    state: State,
    action: GetDashBoardList
  ): State => {
    const dashBordLinksListArray = [...action.payload.rows];
    const dashBordLinksPagination = {
      ...state.dashbordLinksPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      dashbordLinkArray: dashBordLinksListArray,
      dashbordLinksPagination: dashBordLinksPagination,
    };
  },
  ADD_CMS_DASHBORD_LINKS_DATA: (
    state: State,
    action: AddNewMCmsDashBoardData
  ): State => {
    let cmsDashBordLinksForAdd = [...state.dashbordLinkArray];
    if (state.dashbordLinkArray.length < state.dashbordLinksPagination.size) {
      cmsDashBordLinksForAdd.push(action.payload);
    }
    let afterCreateDashBordLinkPagination = {
      ...state.dashbordLinksPagination,
      count: state.dashbordLinksPagination.count + 1,
    };
    return {
      ...state,
      dashbordLinkArray: cmsDashBordLinksForAdd,
      dashbordLinksPagination: afterCreateDashBordLinkPagination,
    };
  },
  Update_CMS_DASHBORD_LINKS_LIST_DATA: (
    state: State,
    action: UpdateCmsDashBoardData
  ): State => {
    let cmsDashBordLiksForUpdate = [...state.dashbordLinkArray];
    let cmsDashBordLiksForUpdateIndex = cmsDashBordLiksForUpdate.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (cmsDashBordLiksForUpdateIndex !== -1) {
      cmsDashBordLiksForUpdate[cmsDashBordLiksForUpdateIndex] = {
        ...cmsDashBordLiksForUpdate[cmsDashBordLiksForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      dashbordLinkArray: cmsDashBordLiksForUpdate,
    };
  },
  DELETE_CMS_DASHBORD_LINKS_LIST_DATA: (
    state: State,
    action: DeleteCmsHelpLinksDataset
  ): State => {
    let cmsDashBoardLinksForDelete = [...state.dashbordLinkArray];
    let cmsDashBoardLinksForDeleteIndex = cmsDashBoardLinksForDelete.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (cmsDashBoardLinksForDeleteIndex !== -1) {
      cmsDashBoardLinksForDelete.splice(cmsDashBoardLinksForDeleteIndex, 1);
    }
    let afterDeletDashBordLinksPagination = {
      ...state.dashbordLinksPagination,
      count: state.dashbordLinksPagination.count - 1,
    };
    return {
      ...state,
      dashbordLinkArray: cmsDashBoardLinksForDelete,
      dashbordLinksPagination: afterDeletDashBordLinksPagination,
    };
  },

  // Notification Update
  GET_NOTIFICATION__LIST: (
    state: State,
    action: GetNotificationList
  ): State => {
    const NotificationArrayListListArray = [...action.payload.rows];
    const NotificationArrayListPagination = {
      ...state.notificationPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      notificationList: NotificationArrayListListArray,
      notificationPagination: NotificationArrayListPagination,
    };
  },
  ADD_NOTIFICATION_DATA: (
    state: State,
    action: AddNewMCmsNotificationData
  ): State => {
    let NotificationForAdd = [...state.notificationList];
    if (state.notificationList.length < state.notificationPagination.size) {
      NotificationForAdd.push(action.payload);
    }
    let afterCreateNotificationPagination = {
      ...state.notificationPagination,
      count: state.notificationPagination.count + 1,
    };
    return {
      ...state,
      notificationList: NotificationForAdd,
      notificationPagination: afterCreateNotificationPagination,
    };
  },
  Update_NOTIFICATION_LIST_DATA: (
    state: State,
    action: UpdateCmsNotificationData
  ): State => {
    let NotificationListForUpdate = [...state.notificationList];
    let NotificationListForUpdateIndex = NotificationListForUpdate.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (NotificationListForUpdateIndex !== -1) {
      NotificationListForUpdate[NotificationListForUpdateIndex] = {
        ...NotificationListForUpdate[NotificationListForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      notificationList: NotificationListForUpdate,
    };
  },
  DELETE_NOTIFICATION_LIST_DATA: (
    state: State,
    action: DeleteCmsNotificationDataset
  ): State => {
    let notificationLinksForDelete = [...state.notificationList];
    let notificationLinksForDeleteIndex = notificationLinksForDelete.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (notificationLinksForDeleteIndex !== -1) {
      notificationLinksForDelete.splice(notificationLinksForDeleteIndex, 1);
    }
    let afterDeletNotificationLinksPagination = {
      ...state.notificationPagination,
      count: state.notificationPagination.count - 1,
    };
    return {
      ...state,
      notificationList: notificationLinksForDelete,
      notificationPagination: afterDeletNotificationLinksPagination,
    };
  },

  // FeedBack Update
  GET_FEEDBACK__LIST: (state: State, action: GetFeedBackList): State => {
    const FeedBackArrayListListArray = [...action.payload.rows];
    const FeedBackArrayListPagination = {
      ...state.notificationPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      feedBackList: FeedBackArrayListListArray,
      feedBackPagination: FeedBackArrayListPagination,
    };
  },
  ADD_FEEDBACK_DATA: (state: State, action: AddNewFeedBackData): State => {
    let feedBackForAdd = [...state.feedBackList];
    if (state.feedBackList.length < state.feedBackPagination.size) {
      feedBackForAdd.push(action.payload);
    }
    let afterCreateFeedBackPagination = {
      ...state.feedBackPagination,
      count: state.feedBackPagination.count + 1,
    };
    return {
      ...state,
      feedBackList: feedBackForAdd,
      feedBackPagination: afterCreateFeedBackPagination,
    };
  },
  Update_FEEDBACK_LIST_DATA: (
    state: State,
    action: UpdatFeedBackData
  ): State => {
    let feedBackListForUpdate = [...state.feedBackList];
    let feedBackListForUpdateIndex = feedBackListForUpdate.findIndex((data) => {
      return data.id === action.payload.id;
    });
    if (feedBackListForUpdateIndex !== -1) {
      feedBackListForUpdate[feedBackListForUpdateIndex] = {
        ...feedBackListForUpdate[feedBackListForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      feedBackList: feedBackListForUpdate,
    };
  },
  DELETE_FEEDBACK_LIST_DATA: (
    state: State,
    action: DeletFeedBackDataset
  ): State => {
    let feedBackLinksForDelete = [...state.feedBackList];
    let feedBackLinksForDeleteIndex = feedBackLinksForDelete.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (feedBackLinksForDeleteIndex !== -1) {
      feedBackLinksForDelete.splice(feedBackLinksForDeleteIndex, 1);
    }
    let afterDeletFeedBackLinksPagination = {
      ...state.feedBackPagination,
      count: state.feedBackPagination.count - 1,
    };
    return {
      ...state,
      feedBackList: feedBackLinksForDelete,
      feedBackPagination: afterDeletFeedBackLinksPagination,
    };
  },

  // ActivityLog Manahgment
  GET_ACTIVITYLOG__LIST: (state: State, action: ActivityLogSet): State => {
    const ActivityLogArrayListList = [...action.payload];
    return {
      ...state,
      activityLogList: ActivityLogArrayListList,
    };
  },
  // Admin ActivityLog Manahgment
  GET_ADMIN_ACTIVITYLOG__LIST: (
    state: State,
    action: AdminActivityLogSet
  ): State => {
    const AdminActivityLogArrayListList = [...action.payload];
    return {
      ...state,
      adminActivityLogList: AdminActivityLogArrayListList,
    };
  },

  // Sub Admin ActivityLog Manahgment
  GET_SUB_ADMIN_ACTIVITYLOG__LIST: (
    state: State,
    action: SubAdminActivityLogSet
  ): State => {
    const subAdminActivityLogArrayListList = [...action.payload];
    return {
      ...state,
      subAdminActivityList: subAdminActivityLogArrayListList,
    };
  },
};
interface CMSProvideProps {
  children: ReactNode;
}
const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
export const CMSProvider: FC<CMSProvideProps> = (props) => {
  const { isAuthenticated } = useAuth();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const setInforTagListData = (payload) => {
    dispatch({ type: "GET_Info_LIST", payload });
  };
  const addInforTagDataSet = (payload) => {
    dispatch({ type: "ADD_CMS_INFO_DATA", payload });
  };
  const udpateInforTagDataSet = (payload) => {
    dispatch({ type: "Update_CMS_INFO_LIST_DATA", payload });
  };
  const removeInforTagDataSet = (payload) => {
    dispatch({ type: "DELETE_CMS_INFO_LIST_DATA", payload });
  };
  // LED
  const setLedCodeListData = (payload) => {
    dispatch({ type: "GET_LED_LIST", payload });
  };
  const addLedCodeDataSet = (payload) => {
    dispatch({ type: "ADD_CMS_LED_DATA", payload });
  };
  const udpateLedCodeDataSet = (payload) => {
    dispatch({ type: "Update_CMS_LED_LIST_DATA", payload });
  };
  const removeLedCodeDataSet = (payload) => {
    dispatch({ type: "DELETE_CMS_LED_LIST_DATA", payload });
  };

  // Tutorial
  const setTutorialListData = (payload) => {
    dispatch({ type: "GET_CMS_TUTORIAL__LIST", payload });
  };
  const addTutorialDataSet = (payload) => {
    dispatch({ type: "ADD_CMS_TUTORIAL_DATA", payload });
  };
  const udpateTutorialDataSet = (payload) => {
    dispatch({ type: "Update_CMS_TUTORIAL_LIST_DATA", payload });
  };
  const removeTutorialDataSet = (payload) => {
    dispatch({ type: "DELETE_CMS_TUTORIAL_LIST_DATA", payload });
  };
  // TOC
  const setTOCData = (payload) => {
    // console.log("setTOCData");
    dispatch({ type: "GET_CMS_TOC__LIST", payload });
  };

  const udpateTOCDataSet = (payload) => {
    // console.log("udpateTOCDataSet");
    dispatch({ type: "Update_CMS_TOC_LIST_DATA", payload });
  };

  // Help Links
  const setHelpLinksListData = (payload) => {
    dispatch({ type: "GET_CMS_HELP_LINKS__LIST", payload });
  };
  const addHelpLinksDataSet = (payload) => {
    dispatch({ type: "ADD_CMS_HELP_LINKS_DATA", payload });
  };
  const udpateHelpLinksDataSet = (payload) => {
    dispatch({ type: "Update_CMS_HELP_LINKS_LIST_DATA", payload });
  };
  const removeHelpLinksDataSet = (payload) => {
    dispatch({ type: "DELETE_CMS_HELP_LINKS_LIST_DATA", payload });
  };

  // Dashbord Links Links
  const setDashbordLinksListData = (payload) => {
    dispatch({ type: "GET_CMS_DASHBORD_LINKS__LIST", payload });
  };
  const addDashbordLinksDataSet = (payload) => {
    dispatch({ type: "ADD_CMS_DASHBORD_LINKS_DATA", payload });
  };
  const udpateDashbordLinksDataSet = (payload) => {
    dispatch({ type: "Update_CMS_DASHBORD_LINKS_LIST_DATA", payload });
  };
  const removeDashbordLinksDataSet = (payload) => {
    dispatch({ type: "DELETE_CMS_DASHBORD_LINKS_LIST_DATA", payload });
  };

  const setNotificationListData = (payload) => {
    dispatch({ type: "GET_NOTIFICATION__LIST", payload });
  };

  const addNotificationDataSet = (payload) => {
    dispatch({ type: "ADD_NOTIFICATION_DATA", payload });
  };

  const removeNotificationDataSet = (payload) => {
    dispatch({ type: "DELETE_NOTIFICATION_LIST_DATA", payload });
  };

  const udpateNotificationDataSet = (payload) => {
    dispatch({ type: "Update_NOTIFICATION_LIST_DATA", payload });
  };

  const setFeedBackListData = (payload) => {
    dispatch({ type: "GET_FEEDBACK__LIST", payload });
  };

  const addFeedBackDataSet = (payload) => {
    dispatch({ type: "ADD_FEEDBACK_DATA", payload });
  };

  const removeFeedBackDataSet = (payload) => {
    dispatch({ type: "DELETE_FEEDBACK_LIST_DATA", payload });
  };

  const udpateFeedBackDataSet = (payload) => {
    dispatch({ type: "Update_FEEDBACK_LIST_DATA", payload });
  };

  const setActivityLog = (payload) => {
    dispatch({ type: "GET_ACTIVITYLOG__LIST", payload });
  };

  const setAdminActivityLog = (payload) => {
    // console.log("payload ==>", payload);
    dispatch({ type: "GET_ADMIN_ACTIVITYLOG__LIST", payload });
  };

  const setAubAdminActivityLog = (payload) => {
    dispatch({ type: "GET_SUB_ADMIN_ACTIVITYLOG__LIST", payload });
  };

  return (
    <CMSContext.Provider
      value={{
        ...state,
        setInforTagListData,
        addInforTagDataSet,
        udpateInforTagDataSet,
        removeInforTagDataSet,

        setLedCodeListData,
        addLedCodeDataSet,
        udpateLedCodeDataSet,
        removeLedCodeDataSet,

        setTutorialListData,
        addTutorialDataSet,
        udpateTutorialDataSet,
        removeTutorialDataSet,

        setTOCData,
        udpateTOCDataSet,

        setHelpLinksListData,
        addHelpLinksDataSet,
        udpateHelpLinksDataSet,
        removeHelpLinksDataSet,

        setDashbordLinksListData,
        addDashbordLinksDataSet,
        udpateDashbordLinksDataSet,
        removeDashbordLinksDataSet,

        setNotificationListData,
        addNotificationDataSet,
        udpateNotificationDataSet,
        removeNotificationDataSet,

        setFeedBackListData,
        addFeedBackDataSet,
        udpateFeedBackDataSet,
        removeFeedBackDataSet,

        setActivityLog,
        setAdminActivityLog,
        setAubAdminActivityLog,
      }}
    >
      {children}
    </CMSContext.Provider>
  );
};
CMSProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CMSContext;
