import {
  Box
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';

interface WraperProps {
  children: ReactNode
}
const ContentBody: FC<WraperProps> = ({ children }) => (
  <Box>
    {children}
  </Box>
);

ContentBody.propTypes = {
  children: PropTypes.node
};

export default ContentBody;
