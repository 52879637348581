import { authApi } from "lib/API/authApi";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useEffect, useReducer } from "react";
import toast from "react-hot-toast";
import { clearStorage, readCookie, setCookie } from "../utils/general";
import addMinutes from "utils/addTime";
import { useNavigate } from "react-router-dom";
import { useUser } from "hooks";

function setCook(name, value) {
  var expires = "";
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

interface infoTagArray {
  icon: {
    file: string;
    path: string;
    mimetype: string;
  };
  id: number;
  short_code: string;
  is_active: boolean;
  title: string;
  content: string;
  createdAt: string;
  updatedAt: string;
}
interface countriesArray {
  id: 1;
  name: string;
  num_code: number;
  phone_code: number;
  short_code: string;
}
interface languageArray {
  id: number;
  name: string;
  iso_code: string;
  status: number;
}
interface masterapiData {
  info_tags: {
    rows: Array<infoTagArray>;
    pagination: {
      count: number;
      page: number;
      limit: number;
    };
  };
  countries: Array<countriesArray>;
  languages: Array<languageArray>;
  hub_endpoint: string;
  warning_popups: {
    SCHEDULE: {
      TIMER: {
        TEXT: string;
      };
      MANUAL: {
        TEXT?: string;
      };
    };
    TIMER: {
      MANUAL: {
        TEXT: string;
      };
      SCHEDULE?: {
        TEXT?: string;
      };
    };
    MANUAL: {
      TIMER: {
        TEXT: "";
      };
      SCHEDULE?: {
        TEXT: "";
      };
    };
  };
  frontend_device_configuration: {
    SWITCH: {
      BEFORE_COUNT_DOWN: number;
      ORANGE_WAITING_COUNT_DOWN: number;
    };
    SOCKET_INTERVAL: {
      reconnectionDelayMax: number;
      path: string;
      forceNew: boolean;
      reconnectionAttempts: number;
      reconnectionDelay: number;
      timeout: number;
      autoConnect?: boolean;
      transports: Array<string>;
    };
  };
  resume_schedule_details: {
    TEXT: string;
  };
  device_types:
    | {
        [key: string]: {
          type: string;
          text: string;
        };
      }
    | any;
}
interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isMFAEnabled: boolean;
  MFAType: number;
  isTOTPVerified: boolean;
  MFAUserId: number;
  masterData: masterapiData;
}

interface AuthContextValue extends State {
  platform: "JWT";
  login: (email: string, password: string) => void;
  verifyTOTP: (code: string, user_id: number) => void;
  logout: () => Promise<void>;
  setMasterData: (data: masterapiData) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    isAuthenticated: boolean;
  };
};

type LoginAction = {
  type: "LOGIN";
};

type LogoutAction = {
  type: "LOGOUT";
};

type MFAAction = {
  type: "UPDATE_MFA";
  payload: {
    isMFAEnabled: boolean;
    MFAType: number;
    isTOTPVerified: boolean;
    MFAUserId: number;
  };
};
type setMasterDataFromApi = {
  type: "SET_MASTER_DATA";
  payload: masterapiData;
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | MFAAction
  | setMasterDataFromApi;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isMFAEnabled: false,
  MFAType: 0,
  isTOTPVerified: false,
  MFAUserId: 0,
  masterData: {
    info_tags: {
      rows: [],
      pagination: {
        count: 0,
        page: 0,
        limit: 0,
      },
    },
    countries: [],
    languages: [],
    hub_endpoint: "",
    warning_popups: {
      SCHEDULE: {
        TIMER: {
          TEXT: "",
        },
        MANUAL: {
          TEXT: "",
        },
      },
      TIMER: {
        MANUAL: {
          TEXT: "",
        },
      },
      MANUAL: {
        TIMER: {
          TEXT: "",
        },
      },
    },
    frontend_device_configuration: {
      SWITCH: {
        BEFORE_COUNT_DOWN: 0,
        ORANGE_WAITING_COUNT_DOWN: 0,
      },
      SOCKET_INTERVAL: {
        reconnectionDelayMax: 0,
        path: "",
        forceNew: true,
        reconnectionAttempts: 0,
        reconnectionDelay: 0,
        timeout: 0,
        autoConnect: true,
        transports: [],
      },
    },
    resume_schedule_details: {
      TEXT: "",
    },
    device_types: {},
  },
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
    };
  },

  LOGIN: (state: State, action: LoginAction): State => ({
    ...state,
    isAuthenticated: true,
    isMFAEnabled: false,
    MFAType: 0,
    isTOTPVerified: false,
    MFAUserId: 0,
  }),
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
  }),
  UPDATE_MFA: (state: State, action: MFAAction): State => {
    const { isMFAEnabled, MFAType, isTOTPVerified, MFAUserId } = action.payload;

    return {
      ...state,
      isMFAEnabled,
      MFAType,
      isTOTPVerified,
      MFAUserId,
    };
  },

  SET_MASTER_DATA: (state: State, action: setMasterDataFromApi): State => {
    const updatedMasterData = { ...state.masterData, ...action.payload };
    return {
      ...state,
      masterData: updatedMasterData,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  verifyTOTP: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setMasterData: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const { setV1UserDataOnLogin } = useUser();

  const masterDatAPIcALL = async () => {
    const currentLang = localStorage.getItem("language") ?? "en";
    let masterDataRes = await authApi.masterApiCall(currentLang);
    
    if (masterDataRes?.status === 200) {
      dispatch({ type: "SET_MASTER_DATA", payload: masterDataRes?.data?.data });
    } else {
      toast.error(masterDataRes?.message ?? "");
    }
  };
  useEffect(() => {
    masterDatAPIcALL();
  }, []);
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const [token] = readCookie();
        if (token) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    };
    initialize();
  }, []);

  const verifyTOTP = async (code: string, user_id: number) => {
    try {
      // var urlencoded = new URLSearchParams();
      // urlencoded.append("code", code.toString());
      // urlencoded.append("user_id", user_id.toString());
      let reqData = {};
      reqData = { ...reqData, code: code, user_id: user_id };
      const res = await authApi.verifyOtp(reqData);

      if (res?.status === 200) {
        let resData = res?.data?.data;

        const { token, refreshToken } = resData?.authentication;

        // const { token: x_access_token } = res?.data?.data?.v1_data;

        const x_access_token = resData?.v1_data?.token ?? null;

        if (token && refreshToken) {
          let sessionTime = addMinutes(new Date(), 15).getTime();

          let v1_user_id = resData?.v1_data?.user_id ?? 0;

          let userData = {
            email: resData?.user?.email,
            roleId: resData?.user?.profile?.role?.id,
          };

          setCookie(
            token,
            refreshToken,
            x_access_token,
            JSON.stringify({ sessionTime: sessionTime }),
            v1_user_id,
            userData
          );

          dispatch({
            type: "LOGIN",
          });
          toast.success(res?.data?.message);
          if (
            res.data.data?.user?.profile?.role.id >= 3 &&
            (!res.data.data?.user?.profile?.is_profile_updated ||
              res.data.data?.user?.profile?.is_profile_updated === undefined ||
              res.data.data?.user?.profile?.is_profile_updated === 0)
          ) {
            navigate("/profile-management");
          } else if (res.data.data?.user?.profile?.role.id >= 3) {
            navigate("/management/device-management");
          }
        } else {
          toast.error("Error in Login !");
        }
      } else {
        toast.error(res?.data?.message);
      }
    } catch (e) {
      toast.error("Error in Login !");
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const res = await authApi.login({ email, password });

      if (res?.status === 200) {
        let responsedata = res.data.data;
        // console.log("Login Time Response", responsedata.auth);
        // debugger;
        let sessionTime = addMinutes(new Date(), 15).getTime();
        let token = responsedata?.authentication?.token ?? "";
        let refreshToken = responsedata?.authentication?.refreshToken ?? "";
        let isMFA = responsedata?.auth?.is_mfa_enable ?? 0;
        let Type = responsedata?.auth?.mfa_type ?? 0;
        let totpVerified = responsedata?.auth?.is_verified ?? 0;
        let userId = responsedata?.id ?? 0;

        let v1_user_id = responsedata?.v1_data?.user_id ?? 0;

        let x_access_token = responsedata?.v1_data?.token ?? "";

        let hour_format = responsedata?.user?.profile?.hour_format;
        setCook("hour_format", hour_format);

        let userData = {
          email: responsedata?.user?.email,
          roleId: responsedata?.user?.profile?.role?.id,
        };

        if ((token || x_access_token) && refreshToken) {
          setV1UserDataOnLogin(responsedata?.v1_data);

          setCookie(
            token,
            refreshToken,
            x_access_token,
            JSON.stringify({ sessionTime: sessionTime }),
            v1_user_id,
            userData
          );

          dispatch({
            type: "LOGIN",
          });

          toast.success(res?.data?.message);
        }

        if (
          res.data.data?.user?.profile?.role.id >= 3 &&
          (!res.data.data?.user?.profile?.is_profile_updated ||
            res.data.data?.user?.profile?.is_profile_updated === undefined ||
            res.data.data?.user?.profile?.is_profile_updated === 0)
        ) {
          navigate("/profile-management");
        } else if (res.data.data?.user?.profile?.role.id >= 3) {
          navigate("/management/device-management");
        }
        if (isMFA) {
          dispatch({
            type: "UPDATE_MFA",
            payload: {
              isMFAEnabled: isMFA === 1,
              MFAType: Type,
              isTOTPVerified: totpVerified === 1,
              MFAUserId: userId,
            },
          });
        }
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (e) {
      toast.error("Error in Login !");
    }
  };

  const logout = async (): Promise<void> => {
    clearStorage();
    dispatch({ type: "LOGOUT" });
  };

  const setMasterData = (payload) => {
    dispatch({ type: "SET_MASTER_DATA", payload });
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        verifyTOTP,
        setMasterData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
