import { DEFAULT_LAN } from 'config/constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { selectedLanguage } from 'utils/general';
import de from './config/languages/de.json';
import en from './config/languages/en.json';
import es from './config/languages/es.json';
import fr from './config/languages/fr.json';

const resources = {
  en: {
    translation: { ...en }
  },
  de: {
    translation: { ...de }
  },
  es: {
    translation: { ...es }
  },
  fr: {
    translation: { ...fr }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: selectedLanguage() || DEFAULT_LAN,
    fallbackLng: selectedLanguage() || DEFAULT_LAN,
    interpolation: {
      escapeValue: false
    }
  });
