import {
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';

interface GridProps {
  children: ReactNode;
  [key: string]: any
}

const HalfGrid: FC<GridProps> = ({ children, ...other }) => (
  <Grid
    item
    md={6}
    xs={12}
    {...other}
  >
    {children}
  </Grid>
);

HalfGrid.propTypes = {
  children: PropTypes.node
};

export default HalfGrid;
