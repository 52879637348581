export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const USER_ROLES = {
  ADMIN: 1,
  SUBADMIN: 2,
  USER: 3,
};

export const ACCESS = {
  all_user: [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN, USER_ROLES.USER],
  dashboard: [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN],
  add_edit_user: [USER_ROLES.ADMIN],
  add_edit_led: [USER_ROLES.ADMIN],
  edit_tutorial: [USER_ROLES.ADMIN],
  sort_Led_Tutorial: [USER_ROLES.ADMIN],
  delete_feedback: [USER_ROLES.ADMIN],
  add_edit_info_point: [USER_ROLES.ADMIN],
  edit_db_images: [USER_ROLES.ADMIN],
  send_notification: [USER_ROLES.ADMIN],
  change_password: [USER_ROLES.USER, USER_ROLES.ADMIN, USER_ROLES.SUBADMIN],
  push_Notification: [USER_ROLES.USER],
  add_edit_version_setting: [USER_ROLES.ADMIN],
  add_edit_link_setting: [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN],
  add_edit_device_configuration: [USER_ROLES.ADMIN],
  add_edit_motor: [USER_ROLES.ADMIN],
  add_edit_help: [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN],
};

export const LANGUGAES = {
  English: "English",
  French: "Française",
  Spanish: "Española",
  German: "Deutsch",
};

export const LANGUGAE_FILTER = [
  { name: LANGUGAES.English, value: "en" },
  { name: LANGUGAES.Spanish, value: "es" },
  { name: LANGUGAES.French, value: "fr" },
  { name: LANGUGAES.German, value: "de" },
];

export const DEFAULT_LAN = "en";

// DEFAULT TIME FORMAT SHOULD BE ACCORDING TO US BASED CONVENTION AS MENTIONED BY CLEINT. CHANGE HERE IF DIFFERENT REGION REQUIREMENT OCCURS.
export const TIME_FORMAT_FOR_LISTING = "YYYY-MM-DD HH:mm:ss";
