import { useAuth } from "hooks";
import { authApi } from "lib/API/authApi";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useEffect, useReducer } from "react";
import type { User, V1UserDataTypeOnLogin } from "../types/user";
import { readCookie } from "../utils/general";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface State {
  user: User | null;
  v1User: V1UserDataTypeOnLogin;
}

const initialState: State = {
  user: null,
  v1User: null,
};

interface UserContextValue extends State {
  updateUserData: (data: any) => void;
  setV1UserDataOnLogin: (data: any) => void;
}

const UserContext = createContext<UserContextValue>({
  ...initialState,
  updateUserData: () => Promise.resolve(),
  setV1UserDataOnLogin: () => Promise.resolve(),
});

interface UserProviderProps {
  children: ReactNode;
}
type AuthSuccessAction = {
  type: "AUTH_SUCCESS";
  payload: {
    user: User | null;
  };
};
type AuthFailAction = {
  type: "AUTH_FAIL";
};
type UpdateUserProfile = {
  type: "SET_USER_DATA";
  payload: {
    user: User | null;
  };
};

type V1UserDataOnLogin = {
  type: "SET_USER_DATA_ON_LOGIN";
  payload: V1UserDataTypeOnLogin;
};

type Action =
  | AuthSuccessAction
  | AuthFailAction
  | UpdateUserProfile
  | V1UserDataOnLogin;

const handlers: Record<string, (state: State, action: Action) => State> = {
  AUTH_SUCCESS: (state: State, action: AuthSuccessAction): State => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  SET_USER_DATA: (state: State, action: AuthSuccessAction): State => {
    let userData = { ...state.user, ...action.payload.user };
    return {
      ...state,
      user: userData,
    };
  },
  AUTH_FAIL: (state: State, action: AuthFailAction): State => ({
    ...state,
    user: null,
  }),

  SET_USER_DATA_ON_LOGIN: (state: State, action: V1UserDataOnLogin): State => {
    // let userData = { ...state.user, ...action.payload.user };
    return {
      ...state,
      v1User: action.payload,
    };
  },
};
const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
export const UserProvider: FC<UserProviderProps> = (props) => {
  const { isAuthenticated } = useAuth();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        if (isAuthenticated) {
          const [token] = readCookie();
          if (token) {
            const user = await authApi.profile();

            if (
              user.data.data?.profile?.role.id >= 3 &&
              (!user.data.data?.profile?.is_profile_updated ||
                user.data.data?.profile?.is_profile_updated === undefined ||
                user.data.data?.profile?.is_profile_updated === 0)
            ) {
              navigate("/profile-management");
            }
            dispatch({
              type: "AUTH_SUCCESS",
              payload: {
                user: user.data.data,
              },
            });
          } else dispatch({ type: "AUTH_FAIL" });
        } else dispatch({ type: "AUTH_FAIL" });
      } catch (err) {
        dispatch({ type: "AUTH_FAIL" });
      }
    };
    initialize();
  }, [isAuthenticated]);
  const updateUserData = async (data: any) => {
    try {
      const res = await authApi.updateProfile(data);
      if (res?.status === 200) {
        dispatch({
          type: "SET_USER_DATA",
          payload: {
            user: res.data.data,
          },
        });
        toast.success(res?.data?.message);
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (e) {
      toast.error("Something wents wrong!");
    }
  };

  const setV1UserDataOnLogin = (payload) => {
    dispatch({ type: "SET_USER_DATA_ON_LOGIN", payload });
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        updateUserData,
        setV1UserDataOnLogin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContext;
