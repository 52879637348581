import { useAuth } from "hooks";
import { authApi } from "lib/API/authApi";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { getTokenFCM } from "./FirebaseSetup";

function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}


const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);
  const { isInitialized, isAuthenticated } = useAuth();
  useEffect(() => {
    // console.log("Notification", Notification);
    if (Notification) {
      let data;
      async function tokenFunc() {
        data = await getTokenFCM(setTokenFound);
        if (data) {
          // var urlencoded = new URLSearchParams();
          // urlencoded.append("device_token", data);
          let reqData = {
            device_token: data,
          };
          if (isAuthenticated) {
            setCookie('device_token', reqData.device_token, 30);
            let res = await authApi.addFCMAccount(reqData);
            if (res && res?.status !== 200) {
              toast.error(res?.data?.message);
            }
          }
        }
        return data;
      }
      tokenFunc();
    }
  }, [setTokenFound, isAuthenticated, Notification]);

  return <></>;
};
Notifications.propTypes = {};
export default Notifications;
