import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Switch,
  Typography,
} from "@material-ui/core";
import Lock from "@material-ui/icons/Lock";
import { ACCESS } from "config/constants";
import { useAuth, useUser } from "hooks";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { el } from "date-fns/locale";
import { authApi } from "lib/API/authApi";


function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function eraseCookie(name) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { logout } = useAuth();
  const { user, updateUserData } = useUser();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handlePushNotificaation = async () => {
    if (
      user?.profile?.push_notification == 0 ||
      user.profile.push_notification == undefined
    ) {
      if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          // console.log("Permissions", permission);
          handlePushNotificationdataUpdate();
        });
      }
    } else {
      handlePushNotificationdataUpdate();
    }
  };
  const handlePushNotificationdataUpdate = async () => {
    let req = {
      push_notification: user?.profile?.push_notification == 1 ? 0 : 1,
    };
    await updateUserData(req);
  };
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    const device_token = getCookie('device_token');
    if(device_token != undefined || device_token != ''){
      authApi.deleteFCMToken(device_token);
      eraseCookie('device_token');
    }
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={user && user.avatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user && user?.profile?.first_name}{" "}
            {user && user?.profile?.last_name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
            sx={{ overflowWrap: "break-word" }}
          >
            {user && user?.email}
          </Typography>
        </Box>
        <Divider />
        {ACCESS.change_password.includes(user && user?.profile?.role?.id) ? (
          <Box sx={{ mt: 2 }}>
            <MenuItem component={RouterLink} to="/change-password">
              <ListItemIcon>
                <Lock fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    {t("Change Password")}
                  </Typography>
                }
              />
            </MenuItem>
            {/* <MenuItem component={RouterLink} to="/change-password"> */}
            {ACCESS.push_Notification.includes(
              user && user?.profile?.role?.id
            ) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  px: 2,
                  py: 1,
                }}
              >
                <ListItemText
                  primary={
                    <Typography color="textPrimary" variant="subtitle2">
                      {t("Push Notification")}
                    </Typography>
                  }
                />
                <Switch
                  checked={user?.profile?.push_notification == 1 ? true : false}
                  color="primary"
                  onChange={() => {
                    handlePushNotificaation();
                  }}
                />
              </Box>
            )}

            {/* </MenuItem> */}
          </Box>
        ) : null}
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            {t("Sign Out")}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
