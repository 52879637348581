import { interceptorFunction } from "lib/interceptor";
import toast from "react-hot-toast";
// import { axiosInstance, } from '../interceptor';
import wait from "../../utils/wait";
let interceptores = interceptorFunction();
class AuthApi {
  async login({ email, password }: { email: string; password: string }) {
    await wait(500);
    try {
      return await interceptores.POST("/account/login/?uadt=2", {
        email,
        password,
      });
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "Error in Login");
      return e;
    }
  }
  async registration(data) {
    await wait(500);
    try {
      return await interceptores.POST("/account/register/?uadt=2", data);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "Error in Login");
      return e;
    }
  }

  async profile() {
    try {
      return await interceptores.GET("/account/details/?uadt=2");
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async addFCMAccount(data) {
    try {
      return await interceptores.POST("/account/device-tokens/?uadt=2", data);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }

  async deleteFCMToken(data) {
    try {
      return await interceptores.DELETE("/account/logout?device_token="+data);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }

  async forgotPassword(email: string) {
    try {
      return await interceptores.POST("/account/forgot-password/?uadt=2", {
        email,
      });
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async changePassword(data: { currentpassword: string; newpassword: string }) {
    try {
      return await interceptores.POST("/account/change-password/?uadt=2", data);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async resetPassword(data) {
    try {
      return await interceptores.POST("/account/reset-password/?uadt=2", data);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async updateProfile(data) {
    try {
      return await interceptores.PUT("/account/details/?uadt=2", data);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async getReport() {
    try {
      return await interceptores.GETFILE("/account/get-report/?uadt=2");
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async updateMFA(data) {
    try {
      return await interceptores.POST("/account/update-auth/?uadt=2", data);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async verifyOtp(data) {
    try {
      return await interceptores.POST("/account/verify-otp/?uadt=2", data);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async resendOtp(data) {
    try {
      return await interceptores.GET(`/account/resend-otp?uadt=2&user_id=${data}`);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async deletAccount() {
    try {
      return await interceptores.DELETE(`/account/delete-account-confirmation`);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async getEmailList(req) {
    try {
      return await interceptores.GET(`/account-emails/?uadt=2&${req}`);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async resendEmailList(id) {
    try {
      return await interceptores.PUT(`/account-emails/${id}/?uadt=2`);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async masterApiCall(data) {
    try {
      return await interceptores.GET(`/general/master?uadt=2&language=${data}`);
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
  async resendVerifyEmail(id) {
    try {
      return await interceptores.POST(`/account-emails/resend-verification-mail/${id}/?uadt=2`, {});
    } catch (e) {
      toast.error(e.response ? e.response?.data?.message : "something wents to wrong");
      return e;
    }
  }
}
// console.log();

export const authApi = new AuthApi();
