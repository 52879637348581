import { Box, Container } from "@material-ui/core";
import { useSettings } from "hooks";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import Scrollbar from "components/Scrollbar";

interface WraperProps {
  pageTitle: string;
  smallContent?: boolean;
  children: ReactNode;
}
const ContentWrapper: FC<WraperProps> = ({
  pageTitle,
  children,
  smallContent,
}) => {
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "100%",
            py: 9,
          }}
        >
          {smallContent ? (
            <Container maxWidth="sm">{children}</Container>
          ) : (
            <Container maxWidth={settings.compact ? "xl" : false}>
              {children}
            </Container>
          )}
        </Box>
      </Scrollbar>
    </>
  );
};

ContentWrapper.propTypes = {
  pageTitle: PropTypes.string,
  smallContent: PropTypes.bool,
  children: PropTypes.node,
};

export default ContentWrapper;
