import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import "nprogress/nprogress.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { HelmetProvider } from "react-helmet-async";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { UserProvider } from "./contexts/UserContext";
import { UtilProvider } from "./contexts/UtilContext";
import { DeviceProvider } from "./contexts/DeviceManagment";
import { CMSProvider } from "./contexts/CMSManagment";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Store from "./hooks/Store";
import "./style/main.scss";
import { MqttProvider } from "contexts/MQTT";

// Sentry.init({
//   dsn: "https://0ef358d22830446aa762bbb46bbc96ef@o525665.ingest.sentry.io/6433262",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MqttProvider>
            <UtilProvider>
              <SettingsProvider>
                <BrowserRouter>
                  {/* <AuthProvider>
                  <UserProvider>
                    <DeviceProvider>
                      <CMSProvider> */}
                  <Store>
                    <App />
                  </Store>
                  {/* </CMSProvider>
                    </DeviceProvider>
                  </UserProvider>
                </AuthProvider> */}
                </BrowserRouter>
              </SettingsProvider>
            </UtilProvider>
          </MqttProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
);
