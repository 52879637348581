import OutlineButton from "components/Form/button/OutlineButton";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";

interface WraperProps {
  BtnLable: string;
  BtnLink?: string;
  BtnComponent?: ReactNode;
  [key: string]: any;
}

const HeaderButton: FC<WraperProps> = ({
  BtnLable,
  BtnLink,
  BtnComponent,
  ...other
}) => (
  <OutlineButton
    label={BtnLable}
    to={BtnLink}
    renderModel={BtnComponent}
    sx={{
      pt: 1.2,
      pb: 1.2,
      pl: 2.5,
      pr: 2.5,
      float: "right",
      textTransform: "uppercase",
    }}
    {...other}
  />
);

HeaderButton.propTypes = {
  BtnLable: PropTypes.string,
  BtnLink: PropTypes.string,
  BtnComponent: PropTypes.node,
};

export default HeaderButton;
