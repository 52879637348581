import { Box, ListProps } from "@material-ui/core";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { matchPath } from "react-router-dom";
import NavItem from "./NavItem";
import { List, ListSubheader } from "@material-ui/core";
interface Item {
  [x: string]: any;
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
  title: string;
  [x: string]: any;
}

const renderNavItems = ({
  depth = 0,
  items,
  pathname,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}): JSX.Element => (
  <>
    {items.reduce(
      (acc, item) =>
        reduceChildRoutes({
          acc,
          item,
          pathname,
          depth,
        }),
      []
    )}
  </>
);

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: JSX.Element[];
  pathname: string;
  item: Item;
  depth: number;
}): Array<JSX.Element> | undefined => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: true,
        },
        pathname
      )
    : false;

  const partialMatch = item.path
    ? !!matchPath(
        {
          path: item.path,
          end: false,
        },
        pathname
      )
    : false;

  if (item.children) {
    if (item.allow) {
      acc.push(
        <NavItem
          active={partialMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch}
          path={item.path}
          title={item.title}
          iconType={item.iconType ?? ""}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            pathname,
          })}
        </NavItem>
      );
    }
  } else if (item && item.allow === true) {
    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        iconType={item.iconType ?? ""}
      />
    );
  }
  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  const { items, pathname, title, ...other } = props;
  return (
    // <>
    //   {renderNavItems({
    //     items,
    //     pathname
    //   })}
    // </>
    // <Box sx={{ mt: other.index !== 0 ? "24px" : 0 }}>
    <Box>
      {other.allow && other.allow === true && (
        <List
          sx={{
            p: 0,
            pt: title !== "" ? 1.5 : 0,
          }}
          subheader={
            <ListSubheader
              disableGutters
              disableSticky
              component="div"
              sx={{
                color: "text.primary",
                fontSize: "0.75rem",
                lineHeight: 2.5,
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            >
              {title}
            </ListSubheader>
          }
          {...other}
        >
          {renderNavItems({
            items,
            pathname,
          })}
        </List>
      )}
    </Box>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

export default NavSection;
