import axios from "axios";
import { readCookie, clearStorage, setCookie } from "../utils/general";
// import { processFailureRequest, processSuccessRequest } from '../Utils/general';
import toast from "react-hot-toast";
import addMinutes from "utils/addTime";
import { authApi } from "./API/authApi";

let interceptor = null;
const {
  REACT_APP_BASE_URL,
  REACT_APP_BASE_URL_APP_V1,
  REACT_APP_BASE_URL_APP_V1_2,
} = process.env;
let baseURL = REACT_APP_BASE_URL;
let baseURLForV1 = REACT_APP_BASE_URL_APP_V1;
let baseURLForV1_2 = REACT_APP_BASE_URL_APP_V1_2;

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

// console.log("Based Url changes", baseURL);
class Interceptors {
  constructor() {
    // Add a request interceptor
    axios.interceptors.request.use(
      function (config) {
        let [token, refreshToken, sessionTime, x_access_token] = readCookie();

        try {
          if (token || x_access_token) {
            const cTime = new Date().getTime();
            //sessionTime =  new Date(parseInt(sessionTime)).getTime();
            sessionTime = JSON.parse(sessionTime)["sessionTime"];

            // if(sessionTime == undefined || sessionTime == ''){
            //   clearStorage();
            //   window.location.href = "/";
            // }

            if (typeof sessionTime == "string") {
              sessionTime = new Date(sessionTime).getTime();
            }
            if (cTime < sessionTime) {
              const st = JSON.stringify({
                sessionTime: addMinutes(new Date(), 15),
              });
              setCookie(token, refreshToken, x_access_token, st);
            } else {
              //console.log('running')

              const device_token = getCookie("device_token");
              if (device_token != undefined || device_token != "") {
                authApi.deleteFCMToken(device_token);
                eraseCookie("device_token");
              }

              toast.error("Session Expired, Please Login Again!");
              clearStorage();
              window.location.href = "/";
            }
          }
        } catch (e) {
          const device_token = getCookie("device_token");
          if (device_token != undefined || device_token != "") {
            authApi.deleteFCMToken(device_token);
            eraseCookie("device_token");
          }
          clearStorage();
          window.location.href = "/";
        }

        if (x_access_token) {
          config["headers"]["x-access-token"] = `${x_access_token}`;
        }
        if (token) config["headers"]["authorization"] = `Bearer ${token}`;
        config["headers"]["Access-Control-Expose-Headers"] = `*`;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        if (response.data.status === -2 || response.data.status === -1) {
          clearStorage();
          window.location.href = "/";
          return Promise.reject(response.data.message);
        }

        return response;
      },
      function (error) {
        if (error.response?.status === 401 || error.response?.status === -2) {
          clearStorage();
          if (window.location.pathname !== "/") {
            window.location.href = "/";
          }
        }
        return Promise.reject(error);
      }
    );
  }

  GET(url, version_flag?: string) {
    if (version_flag && version_flag === "V1") {
      return axios
        .get(baseURLForV1 + url)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    } else {
      return axios
        .get(baseURL + url)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    }
  }
  GETFILE(url) {
    return axios
      .get(baseURL + url, {
        responseType: "blob",
        headers: {
          "Access-Control-Expose-Headers": "*",
        },
      })
      .then((response) => {
        // console.log("Response ===>intercepter", response);
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  POST(url, data, version_flag?: string, BASE_URL?: string) {
    if (version_flag && version_flag === "V1") {
      if (BASE_URL && BASE_URL === "BASE_URL_2") {
        return axios
          .post(baseURLForV1_2 + url, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error.response;
          });
      } else {
        return axios
          .post(baseURLForV1 + url, data)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            return error.response;
          });
      }
    } else {
      return axios
        .post(baseURL + url, data)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    }
  }

  PUT(url, data, version_flag?: string) {
    if (version_flag && version_flag === "V1") {
      return axios
        .put(baseURLForV1 + url, data)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    } else {
      return axios
        .put(baseURL + url, data)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error.response;
        });
    }
  }
  DELETEWITHDATA(url, data) {
    return axios
      .delete(baseURL + url, { data: data })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  DELETE(url) {
    return axios
      .delete(baseURL + url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  PATCH(url, data) {
    return axios
      .patch(baseURL + url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
  GETPARAMS(url, parames) {
    return axios(baseURL + url, {
      params: { ...parames },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }
}
export const interceptorFunction = () => {
  if (interceptor === null) {
    // console.log("Function", Interceptors);

    interceptor = new Interceptors();
  }
  return interceptor;
};
export default Interceptors;
