import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';

interface TitleProps {
  children: ReactNode;
}

const PageTitle: FC<TitleProps> = ({ children }) => (
  <Typography
    color="textPrimary"
    variant="h5"
    sx={{
      fontWeight: 500,
      display: 'inline-block',
      marginRight: 2
    }}
  >
    {children}
  </Typography>
);

PageTitle.propTypes = {
  children: PropTypes.node
};

export default PageTitle;
