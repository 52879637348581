import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useReducer } from "react";
import { useAuth } from "../hooks";
import {
  Action,
  AddRelayData,
  AddSheduleData,
  DeleteRelayDataset,
  DeleteSheduleDataset,
  DeleteUserDeviceDataset,
  DisabledAllDeviceShedule,
  EnabledAllDeviceshedule,
  GetRelayList,
  GetSheduleList,
  GetUserDeviceList,
  ManageDeviceModeTab,
  SetDeviceData,
  SetSelectedDeviceRelayData,
  UpdateRelayData,
  UpdateSelectedDeviceRelayData,
  UpdateSheduleData,
  UpdateUserDeviceData,
  AddUserDeviceData,
  UpdateDeviceData,
  ManagePlatformTag,
  IsEditableInterface,
  SetV1RelayList,
  AddV1RelayData,
  DeleteV1RelayDataset,
  SetV1DeviceData,
  UpdateV1RelayData,
  DeleteUserDeviceDatasetV1,
} from "./Interface/UserDevices/UserDevicesActions";
import {
  State,
  UserDeviceContextValue,
} from "./Interface/UserDevices/UserDevicesInterface";
import { mqttMessage } from "./MQTT";
const initialState: State = {
  userDeviceList: [],
  userDevicesRelayList: [],
  userDeviceSheduleList: [],
  userDeviceSelectedrelay: {
    id: 0,
    name: "",
    status: 0,
    device_id: 0,
    relay_number: 0,
    is_default: 0,
    is_lock: 0,
    mode: 0,
    speed: 0,
    end_timer: {
      in_seconds_fr: 0,
      in_time_fr: "00:00:00",
    },
    pri_end_timer: {
      in_seconds_fr: 0,
      in_time_fr: "00:00:00",
    },
    op_mode: 0,
    leak_sensor: 0,
    auto_priming: 0,
    priming_duration: 0,
    priming_speed: 0,
    hard_lock: 0,
    createdAt: "",
    updatedAt: "",
    last_target_speed: 0,
  },
  userDeviceSheduleListPaination: {
    count: 0,
    page: 0,
    size: 0,
  },
  userDevicesRelayPagination: {
    count: 0,
    page: 0,
    size: 0,
  },
  userDevicePagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  userSelectedDevice: {
    id: 0,
    createdAt: "",
    updatedAt: "",
    owner_id: 0,
    device_model_id: 0,
    timezone: 0,
    is_dst_timezone: 0,
    serial_number: "",
    nickname: "",
    mac_address: "",
    date_purchased: "",
    address: "",
    address_lat: 0,
    address_long: 0,
    version: "",
    status: 0,
    is_configured: 0,
    connection_state: 0,
    power_usage: 0,
    device_model: {
      image: {
        file: "",
        path: "",
        mimetype: "",
      },
      id: 0,
      name: "",
      status: 0,
      description: "",
      device_type_id: 0,
      configuration_id: 0,
      device_configuration: {
        id: 0,
        max_additional_ports: 0,
        status: 0,
        speed_control: 0,
        priming: 0,
        priming_speed: 0,
        priming_duration: 0,
        default_motor_speed: 0,
        motor_speed_min: 0,
        motor_speed_max: 0,
        flood_detection: 0,
        control_type: 0,
        motor_id: 0,
        schedule_mode: 0,
        predefined_port: 0,
      },
    },
    device_owner: {
      email: "harryk99@mailinator.com",
      profile: {
        first_name: "",
        last_name: "",
      },
    },
    is_editable: false,
  },
  userTabMode: 0,
  platformTagInfo: null,
  is_editable: true,
  v1RelayList: [],
  userSelectedV1Device: {
    device_id: 0,
    model_number: "",
    serial_number: "",
    nickname: "",
    mac_address: "",
    address: "",
    timezone: "",
    timezone_value: "",
    is_dst_timezone: 0,
    address_lat: "",
    address_long: "",
    isEditable: 0,
    device_version: "",
    owner_id: 0,
    created_date: "",
    updated_date: "",
  },
};

const UserDeviceContext = createContext<UserDeviceContextValue>({
  ...initialState,

  setUserDeviceListData: () => Promise.resolve(),
  udpateUserDeviceDataSet: () => Promise.resolve(),
  udpateUserDeviceDataSetV1: () => Promise.resolve(),
  UpdateDeviceStatusInDeviceListV1: () => Promise.resolve(),
  removeUserDeviceDatafromSet: () => Promise.resolve(),
  removeUserDeviceDatafromSetV1: () => Promise.resolve(),
  addDevicesInDataSet: () => Promise.resolve(),
  setDeviceData: () => Promise.resolve(),
  updateSelectedData: () => Promise.resolve(),

  setRelayListData: () => Promise.resolve(),
  setDeviceRelayData: () => Promise.resolve(),
  addRelayDataSet: () => Promise.resolve(),
  updateDeviceRelayData: () => Promise.resolve(),
  udpateRelayDataSet: () => Promise.resolve(),
  removeRelayDatafromSet: () => Promise.resolve(),

  setSheduleListData: () => Promise.resolve(),
  addSheduleDataSet: () => Promise.resolve(),
  udpateSheduleDataSet: () => Promise.resolve(),
  removeSheduleDatafromSet: () => Promise.resolve(),
  enableAllShedule: () => Promise.resolve(),
  disableAllShedule: () => Promise.resolve(),

  updateUserDeviceTabMode: () => Promise.resolve(),
  setPlatformTag: () => Promise.resolve(),
  setIsEditable: () => Promise.resolve(),
  setV1RelayList: () => Promise.resolve(),
  addV1RelayDataSet: () => Promise.resolve(),
  removeV1RelayFromDataSet: () => Promise.resolve(),
  setV1DeviceData: () => Promise.resolve(),
  updateV1RelayDataSet: () => Promise.resolve(),
});
const handlers: Record<string, (state: State, action: Action) => State> = {
  // Useres Device  Managment functions.
  ADD_USER_DEVIE_LIST_DATA: (
    state: State,
    action: AddUserDeviceData
  ): State => {
    let deviceListDataForAdd = [...state.userDeviceList];
    let checkindex = deviceListDataForAdd.findIndex((data) => {
      return data.id === action.payload.id;
    });
    let afterAddDevicesPagination = {
      ...state.userDevicePagination,
    };
    if (checkindex === -1) {
      deviceListDataForAdd.push(action.payload);
      afterAddDevicesPagination = {
        ...state.userDevicePagination,
        count: state.userDevicePagination.count + 1,
      };
    }

    return {
      ...state,
      userDeviceList: deviceListDataForAdd,
      userDevicePagination: afterAddDevicesPagination,
    };
  },
  GET_USER_DEVIE_LIST: (state: State, action: GetUserDeviceList): State => {
    const userDevicesListArray = [...action.payload.rows];
    const userDevicePaginationData = {
      ...state.userDevicePagination,
      page: +action.payload.pagination.page,
      limit: +action.payload.pagination.size,
      count: +action.payload.pagination.count,
    };
    return {
      ...state,
      userDeviceList: userDevicesListArray,
      userDevicePagination: userDevicePaginationData,
    };
  },
  Update_USER_DEVIE_LIST_DATA: (
    state: State,
    action: UpdateUserDeviceData
  ): State => {
    let userDeviceDataForUpdate = [...state.userDeviceList];
    let userDeviceDataForUpdateIndex = userDeviceDataForUpdate.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );

    if (userDeviceDataForUpdateIndex !== -1) {
      userDeviceDataForUpdate[userDeviceDataForUpdateIndex] = {
        ...userDeviceDataForUpdate[userDeviceDataForUpdateIndex],
        ...action.payload,
      };
    }
    // console.log("Data Updates", userDeviceDataForUpdate);

    return {
      ...state,
      userDeviceList: userDeviceDataForUpdate,
    };
  },
  UPDATE_USER_DEVICE_LIST_DATA_V1: (
    state: State,
    action: UpdateUserDeviceData
  ): State => {
    let userDeviceDataForUpdate = [...state.userDeviceList];
    let userDeviceDataForUpdateIndex = userDeviceDataForUpdate.findIndex(
      (data) => {
        return data["v1_device_id"] === +action.payload?.device_id;
      }
    );

    action.payload["v1_device_id"] = +action.payload?.device_id;
    action.payload["v1_device_serial_number"] = action.payload?.serial_number;

    delete action.payload.device_id;

    if (userDeviceDataForUpdateIndex !== -1) {
      userDeviceDataForUpdate[userDeviceDataForUpdateIndex] = {
        ...userDeviceDataForUpdate[userDeviceDataForUpdateIndex],
        ...action.payload,
      };
    }

    return {
      ...state,
      userDeviceList: userDeviceDataForUpdate,
    };
  },
  UPDATE_DEVICE_STATUS_IN_DEVICE_LIST_V1: (
    state: State,
    action: mqttMessage
  ): State => {
    let userDeviceDataForUpdateDeviceStatus = [...state.userDeviceList];

    const matchString = action.payload.topic.match(/(\w{6})\/Status/);

    let deviceIdx = userDeviceDataForUpdateDeviceStatus.findIndex((item) => {
      return item.mac_address.slice(-6) === matchString[1];
    });

    if (deviceIdx !== -1) {
      userDeviceDataForUpdateDeviceStatus[deviceIdx] = {
        ...userDeviceDataForUpdateDeviceStatus[deviceIdx],
        connection_state: action.payload.payloadString === "Online" ? 1 : 0,
      };
    }

    return {
      ...state,
      userDeviceList: userDeviceDataForUpdateDeviceStatus,
    };
  },
  DELETE_USER_DEVIE_LIST_DATA: (
    state: State,
    action: DeleteUserDeviceDataset
  ): State => {
    let userDeviceDataForDelete = [...state.userDeviceList];
    let userDeviceDataForDeleteIndex = userDeviceDataForDelete.findIndex(
      (data) => {
        return data.id === action.payload?.id;
      }
    );
    if (userDeviceDataForDeleteIndex !== -1) {
      userDeviceDataForDelete.splice(userDeviceDataForDeleteIndex, 1);
    }
    let afterDeletUserDevicePagination = {
      ...state.userDevicePagination,
      count: state.userDevicePagination.count - 1,
    };
    return {
      ...state,
      userDeviceList: userDeviceDataForDelete,
      userDevicePagination: afterDeletUserDevicePagination,
    };
  },
  DELETE_USER_DEVICE_LIST_DATA_V1: (
    state: State,
    action: DeleteUserDeviceDatasetV1
  ): State => {
    let userDeviceDataForDelete = [...state.userDeviceList];

    let userDeviceDataForDeleteIndex = userDeviceDataForDelete.findIndex(
      (data) => {
        return data["v1_device_id"] === +action.payload;
      }
    );

    if (userDeviceDataForDeleteIndex !== -1) {
      userDeviceDataForDelete.splice(userDeviceDataForDeleteIndex, 1);
    }

    let afterDeletUserDevicePagination = {
      ...state.userDevicePagination,
      count: state.userDevicePagination.count - 1,
    };

    return {
      ...state,
      userDeviceList: userDeviceDataForDelete,
      userDevicePagination: afterDeletUserDevicePagination,
    };
  },
  SET_SELECTED_DEVICE_DATA: (state: State, action: SetDeviceData): State => {
    let setDeviceDataset = { ...state.userSelectedDevice, ...action.payload };
    return {
      ...state,
      userSelectedDevice: setDeviceDataset,
    };
  },

  UPDATE_SELECTED_DEVICE_DATA: (
    state: State,
    action: UpdateDeviceData
  ): State => {
    let selectedDeviceDataset = { ...state.userSelectedDevice };
    // let findIndex =
    if (selectedDeviceDataset.device_id === action.payload.device_id) {
      let selectedDeviceDatasetRelay = selectedDeviceDataset.relays.map(
        (data) => {
          return { ...data };
        }
      );
      let findIndexRelayId = selectedDeviceDatasetRelay.findIndex((data) => {
        return data.id === action.payload.data.relay_id;
      });
      if (action.payload.from === "ADDED") {
        if (findIndexRelayId !== -1) {
          let newCount = 2 + action.payload.data.variations.length;
          selectedDeviceDatasetRelay[findIndexRelayId] = {
            ...selectedDeviceDatasetRelay[findIndexRelayId],
            schedules_count:
              selectedDeviceDatasetRelay[findIndexRelayId].schedules_count +
              newCount,
          };
        }
        // selectedDeviceDatasetRelay.push(action.payload.data);
      } else {
        if (findIndexRelayId !== -1) {
          let newCount = 2 + action.payload.data.variations.length;
          selectedDeviceDatasetRelay[findIndexRelayId] = {
            ...selectedDeviceDatasetRelay[findIndexRelayId],
            schedules_count:
              selectedDeviceDatasetRelay[findIndexRelayId].schedules_count -
              newCount,
          };
        }
      }
      selectedDeviceDataset = {
        ...selectedDeviceDataset,
        relays: selectedDeviceDatasetRelay,
      };
    }
    return {
      ...state,
      userSelectedDevice: selectedDeviceDataset,
    };
  },

  // Device Relay functions.
  GET_DEVICE_RELAY_LIST: (state: State, action: GetRelayList): State => {
    const relayListArray = [...action.payload.rows];
    const relayDataPagination = {
      ...state.userDevicesRelayPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      userDevicesRelayList: relayListArray,
      userDevicesRelayPagination: relayDataPagination,
    };
  },

  ADD_DEVICE_RELAY_DATA: (state: State, action: AddRelayData): State => {
    let relayListDataForAdd = [...state.userDevicesRelayList];
    let relayCheckId = relayListDataForAdd.findIndex((data) => {
      return data.id === action.payload?.id;
    });
    let afterCreateMotorPagination = {
      ...state.userDevicesRelayPagination,
    };
    if (relayCheckId === -1) {
      relayListDataForAdd.push(action.payload);
      afterCreateMotorPagination = {
        ...state.userDevicesRelayPagination,
        count: state.userDevicesRelayPagination.count + 1,
      };
    }

    return {
      ...state,
      userDevicesRelayList: relayListDataForAdd,
      userDevicesRelayPagination: afterCreateMotorPagination,
    };
  },
  Update_DEVICE_RELAY_LIST_DATA: (
    state: State,
    action: UpdateRelayData
  ): State => {
    let relayListDataForUpdate = [...state.userDevicesRelayList];
    let relayListDataForUpdateIndex = relayListDataForUpdate.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (relayListDataForUpdateIndex !== -1) {
      relayListDataForUpdate[relayListDataForUpdateIndex] = {
        ...relayListDataForUpdate[relayListDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      userDevicesRelayList: relayListDataForUpdate,
    };
  },
  DELETE_DEVICE_RELAY_LIST_DATA: (
    state: State,
    action: DeleteRelayDataset
  ): State => {
    let relayListDataForDelete = [...state.userDevicesRelayList];
    let relayListDataForDeleteIndex = relayListDataForDelete.findIndex(
      (data) => {
        return data.id === action.payload?.id;
      }
    );

    if (relayListDataForDeleteIndex !== -1) {
      relayListDataForDelete.splice(relayListDataForDeleteIndex, 1);
    }
    let afterDeleteRelayPagination = {
      ...state.userDevicesRelayPagination,
      count: state.userDevicesRelayPagination.count - 1,
    };
    return {
      ...state,
      userDevicesRelayList: relayListDataForDelete,
      userDevicesRelayPagination: afterDeleteRelayPagination,
    };
  },
  SET_SELECTED_RELAY_DATA: (
    state: State,
    action: SetSelectedDeviceRelayData
  ): State => {
    let setDeviceRelayDataset = {
      ...state.userDeviceSelectedrelay,
      ...action.payload,
    };
    return {
      ...state,
      userDeviceSelectedrelay: setDeviceRelayDataset,
    };
  },
  UPDATE_SELECTED_RELAY_DATA: (
    state: State,
    action: UpdateSelectedDeviceRelayData
  ): State => {
    // console.log("Update data", action.payload);
    let setDeviceRelayDataset = {
      ...state.userDeviceSelectedrelay,
    };
    if (state.userDeviceSelectedrelay.id === action.payload.id) {
      setDeviceRelayDataset = {
        ...state.userDeviceSelectedrelay,
        ...action.payload,
      };
    }
    return {
      ...state,
      userDeviceSelectedrelay: setDeviceRelayDataset,
    };
  },
  // Device Shedule functions.
  GET_DEVICE_SHEDULE_LIST: (state: State, action: GetSheduleList): State => {
    const sheduleListArray = [...action.payload.rows];
    const sheduleDataPagination = {
      ...state.userDeviceSheduleListPaination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      userDeviceSheduleList: sheduleListArray,
      userDeviceSheduleListPaination: sheduleDataPagination,
    };
  },

  ADD_DEVICE_SHEDULE_DATA: (state: State, action: AddSheduleData): State => {
    let sheduleListDataForAdd = [...state.userDeviceSheduleList];
    let sdeduleIndex = sheduleListDataForAdd.findIndex((data) => {
      return data.id === action.payload?.id;
    });
    let afterCreateShedulePagination = {
      ...state.userDeviceSheduleListPaination,
    };
    if (
      sdeduleIndex === -1 &&
      state.userDeviceSelectedrelay.id === action.payload?.relay_id
    ) {
      sheduleListDataForAdd.push(action.payload);
      afterCreateShedulePagination = {
        ...state.userDeviceSheduleListPaination,
        count: state.userDeviceSheduleListPaination.count + 1,
      };
    }
    return {
      ...state,
      userDeviceSheduleList: sheduleListDataForAdd,
      userDeviceSheduleListPaination: afterCreateShedulePagination,
    };
  },
  Update_DEVICE_SHEDULE_LIST_DATA: (
    state: State,
    action: UpdateSheduleData
  ): State => {
    let sheduleListDataForUpdate = [...state.userDeviceSheduleList];
    let sheduleListDataForUpdateIndex = sheduleListDataForUpdate.findIndex(
      (data) => {
        return data.group_id === action.payload.group_id;
      }
    );
    if (sheduleListDataForUpdateIndex !== -1) {
      sheduleListDataForUpdate[sheduleListDataForUpdateIndex] = {
        ...sheduleListDataForUpdate[sheduleListDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      userDeviceSheduleList: sheduleListDataForUpdate,
    };
  },
  DELETE_DEVICE_SHEDULE_LIST_DATA: (
    state: State,
    action: DeleteSheduleDataset
  ): State => {
    let sheduleListDataForDelete = [...state.userDeviceSheduleList];

    let sheduleListDataForDeleteIndex = sheduleListDataForDelete.findIndex(
      (data) => {
        return data.group_id === action.payload.group_id;
      }
    );

    if (sheduleListDataForDeleteIndex !== -1) {
      sheduleListDataForDelete.splice(sheduleListDataForDeleteIndex, 1);
    }
    let afterDeleteShedulePagination = {
      ...state.userDeviceSheduleListPaination,
      count: state.userDeviceSheduleListPaination.count - 1,
    };
    return {
      ...state,
      userDeviceSheduleList: sheduleListDataForDelete,
      userDeviceSheduleListPaination: afterDeleteShedulePagination,
    };
  },
  ENABLE_ALL_SHEDULE: (
    state: State,
    action: EnabledAllDeviceshedule
  ): State => {
    let enableSheduleList = [...state.userDeviceSheduleList];
    enableSheduleList = enableSheduleList.map((data) => {
      return {
        ...data,
        status: 1,
      };
    });
    return {
      ...state,
      userDeviceSheduleList: enableSheduleList,
    };
  },
  DISABLE_ALL_SHEDULE: (
    state: State,
    action: DisabledAllDeviceShedule
  ): State => {
    let disableSheduleList = [...state.userDeviceSheduleList];
    disableSheduleList = disableSheduleList.map((data) => {
      return {
        ...data,
        status: 0,
      };
    });
    return {
      ...state,
      userDeviceSheduleList: disableSheduleList,
    };
  },

  UPDATE_TAB_SELECTION: (state: State, action: ManageDeviceModeTab): State => {
    return {
      ...state,
      userTabMode: action.payload,
    };
  },

  SET_PLATFORM_TAG: (state: State, action: ManagePlatformTag): State => {
    return {
      ...state,
      platformTagInfo: action.payload,
    };
  },

  SET_IS_EDITABLE: (state: State, action: IsEditableInterface): State => {
    return {
      ...state,
      is_editable: action.is_editable,
    };
  },

  SET_V1_RELAY_LIST: (state: State, action: SetV1RelayList): State => {
    return {
      ...state,
      v1RelayList: action.payload,
    };
  },

  ADD_V1_RELAY_DATA: (state: State, action: AddV1RelayData): State => {
    return {
      ...state,
      v1RelayList: [...state.v1RelayList, action.payload],
    };
  },

  DELETE_V1_RELAY_LIST_DATA: (
    state: State,
    action: DeleteV1RelayDataset
  ): State => {
    const elementsAfterDeletion = state.v1RelayList.filter((element) => {
      return element.relay_number !== action.relay_number && element;
    });

    return {
      ...state,
      v1RelayList: elementsAfterDeletion,
    };
  },

  SET_SELECTED_V1_DEVICE_DATA: (
    state: State,
    action: SetV1DeviceData
  ): State => {
    let setDeviceDataset = { ...state.userSelectedDevice, ...action.payload };

    return {
      ...state,
      userSelectedV1Device: setDeviceDataset,
    };
  },

  UPDATE_V1_RELAY_DATA: (state: State, action: UpdateV1RelayData): State => {
    const updatedRelayList = state.v1RelayList.map((existingRelay) => {
      if (existingRelay.relay_id === action.payload.relay_id) {
        // existingRelay.nickname = action.payload.nickname;
        existingRelay = { ...action.payload };
      }
      return existingRelay;
    });

    return {
      ...state,
      v1RelayList: updatedRelayList,
    };
  },
};
interface UserDeviceProvideProps {
  children: ReactNode;
}
const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
export const UserDeviceProvider: FC<UserDeviceProvideProps> = (props) => {
  const { isAuthenticated } = useAuth();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  // Useres Device  Managment functions.
  const setUserDeviceListData = async (payload) => {
    // console.log("setMortorListData call");
    dispatch({ type: "GET_USER_DEVIE_LIST", payload });
  };
  const addDevicesInDataSet = async (payload) => {
    dispatch({ type: "ADD_USER_DEVIE_LIST_DATA", payload });
  };
  const udpateUserDeviceDataSet = async (payload) => {
    dispatch({ type: "Update_USER_DEVIE_LIST_DATA", payload });
  };
  const udpateUserDeviceDataSetV1 = async (payload) => {
    dispatch({ type: "UPDATE_USER_DEVICE_LIST_DATA_V1", payload });
  };
  const UpdateDeviceStatusInDeviceListV1 = async (payload) => {
    dispatch({ type: "UPDATE_DEVICE_STATUS_IN_DEVICE_LIST_V1", payload });
  };
  const removeUserDeviceDatafromSet = async (payload) => {
    dispatch({ type: "DELETE_USER_DEVIE_LIST_DATA", payload });
  };
  const removeUserDeviceDatafromSetV1 = async (payload) => {
    dispatch({ type: "DELETE_USER_DEVICE_LIST_DATA_V1", payload });
  };
  const setDeviceData = async (payload) => {
    dispatch({ type: "SET_SELECTED_DEVICE_DATA", payload });
  };

  // Device Relay Managment functions.
  const setRelayListData = async (payload) => {
    // console.log("setMortorListData call");
    dispatch({ type: "GET_DEVICE_RELAY_LIST", payload });
  };
  const addRelayDataSet = async (payload) => {
    dispatch({ type: "ADD_DEVICE_RELAY_DATA", payload });
  };
  const udpateRelayDataSet = async (payload) => {
    dispatch({ type: "Update_DEVICE_RELAY_LIST_DATA", payload });
  };
  const removeRelayDatafromSet = async (payload) => {
    dispatch({ type: "DELETE_DEVICE_RELAY_LIST_DATA", payload });
  };

  // Device Shedule Managment functions.
  const setSheduleListData = async (payload) => {
    // console.log("setMortorListData call");
    dispatch({ type: "GET_DEVICE_SHEDULE_LIST", payload });
  };
  const addSheduleDataSet = async (payload) => {
    dispatch({ type: "ADD_DEVICE_SHEDULE_DATA", payload });
  };
  const udpateSheduleDataSet = async (payload) => {
    dispatch({ type: "Update_DEVICE_SHEDULE_LIST_DATA", payload });
  };
  const removeSheduleDatafromSet = async (payload) => {
    dispatch({ type: "DELETE_DEVICE_SHEDULE_LIST_DATA", payload });
  };

  const setDeviceRelayData = async (payload) => {
    dispatch({ type: "SET_SELECTED_RELAY_DATA", payload });
  };
  const enableAllShedule = () => {
    dispatch({ type: "ENABLE_ALL_SHEDULE" });
  };
  const disableAllShedule = () => {
    dispatch({ type: "DISABLE_ALL_SHEDULE" });
  };
  const updateDeviceRelayData = (payload) => {
    dispatch({ type: "UPDATE_SELECTED_RELAY_DATA", payload });
  };

  const updateUserDeviceTabMode = (payload) => {
    dispatch({ type: "UPDATE_TAB_SELECTION", payload });
  };

  const updateSelectedData = (payload) => {
    dispatch({ type: "UPDATE_SELECTED_DEVICE_DATA", payload });
  };

  const setPlatformTag = (payload) => {
    dispatch({ type: "SET_PLATFORM_TAG", payload });
  };

  const setIsEditable = (is_editable) => {
    dispatch({ type: "SET_IS_EDITABLE", is_editable });
  };

  const setV1RelayList = (payload) => {
    dispatch({ type: "SET_V1_RELAY_LIST", payload });
  };

  const addV1RelayDataSet = async (payload) => {
    dispatch({ type: "ADD_V1_RELAY_DATA", payload });
  };

  const updateV1RelayDataSet = async (payload) => {
    dispatch({ type: "UPDATE_V1_RELAY_DATA", payload });
  };

  const removeV1RelayFromDataSet = async (relay_number) => {
    dispatch({ type: "DELETE_V1_RELAY_LIST_DATA", relay_number });
  };

  const setV1DeviceData = async (payload) => {
    dispatch({ type: "SET_SELECTED_V1_DEVICE_DATA", payload });
  };

  return (
    <UserDeviceContext.Provider
      value={{
        ...state,

        setUserDeviceListData,
        udpateUserDeviceDataSet,
        udpateUserDeviceDataSetV1,
        UpdateDeviceStatusInDeviceListV1,
        removeUserDeviceDatafromSet,
        removeUserDeviceDatafromSetV1,
        setDeviceData,
        addDevicesInDataSet,

        setRelayListData,
        addRelayDataSet,
        udpateRelayDataSet,
        removeRelayDatafromSet,
        setDeviceRelayData,
        updateDeviceRelayData,

        setSheduleListData,
        addSheduleDataSet,
        udpateSheduleDataSet,
        removeSheduleDatafromSet,

        enableAllShedule,
        disableAllShedule,

        updateUserDeviceTabMode,
        updateSelectedData,
        setPlatformTag,
        setIsEditable,
        setV1RelayList,
        addV1RelayDataSet,
        removeV1RelayFromDataSet,
        setV1DeviceData,
        updateV1RelayDataSet,
      }}
    >
      {children}
    </UserDeviceContext.Provider>
  );
};
UserDeviceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserDeviceContext;
