import { devicehApi } from "lib/API/deviceApi";
import React from "react";
import toast from "react-hot-toast";
import { io } from "socket.io-client";
import { useUserDeviceManagment } from "../../hooks";
import { useAuth, useSettings, useDeviceManagment, UseUserDataSettings } from "hooks";
import { readCookie } from "../../utils/general";
import SocketListeners from "./Listeners";
import { duration } from "moment";
import { authApi } from "lib/API/authApi";
import { useNavigate, useLocation } from "react-router-dom";
let initialState = {
  socket: null,
};
export const SocketContext = React.createContext(initialState);
export const SocketProvider = (props) => {
  const {
    setUserDeviceListData,
    udpateUserDeviceDataSet,
    removeUserDeviceDatafromSet,
    setDeviceData,
    updateSelectedData,
    addDevicesInDataSet,

    setRelayListData,
    addRelayDataSet,
    udpateRelayDataSet,
    removeRelayDatafromSet,
    setDeviceRelayData,
    updateDeviceRelayData,

    setSheduleListData,
    addSheduleDataSet,
    udpateSheduleDataSet,
    removeSheduleDatafromSet,

    enableAllShedule,
    disableAllShedule,

    userSelectedDevice,
    userDeviceSelectedrelay,
  } = useUserDeviceManagment();
  const { updateListofFirmwareData, removerListDataOfFirmware } = useDeviceManagment();
  const {
    setDeviceSettingsUpdateLoader,
    setuserInitalized,
    setuserDownloading,
    setuserFininshed,
    setuserFailed,
    setuserTerminated,
  } = useSettings();
  const {
    setUserCountSocketData,
    setDeviceCountSocketData,
    setConnectedDevicSocketData,
    setConnectedDeviceSocketSocketData,
  } = UseUserDataSettings();
  const [value, setValue] = React.useState({
    queueLength: 0,
    positionInLine: 0,
  });
  const { isAuthenticated } = useAuth();
  const [token] = readCookie();
  const handleAddSharedDevices = async (data) => {
    // console.log("Data context call");
    let res = await devicehApi.getDeviciesByid(data.id);
    if (res?.status === 200) {
      addDevicesInDataSet(res.data.data);
    } else {
      toast.error(`${res?.data?.message}`);
    }
  };
  const handleAddDevices = async (data) => {
    // console.log("Data context call");
    let res = await devicehApi.getDeviciesByid(data.id);
    if (res?.status === 200) {
      addDevicesInDataSet(res.data.data);
    } else {
      toast.error(`${res?.data?.message}`);
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  let isNavigate = location.pathname.includes(`/management/device-management/device/`);
  // console.log("Location ", location.pathname, isNavigate);

  const handleDeviceUpdate = async (deviceData) => {
    // console.log(
    //   "Payload device Update ====>",
    //   deviceData,
    //   userSelectedDevice.connection_state
    // );
    if (
      deviceData.device_id === userSelectedDevice.device_id &&
      deviceData.data.connection_state == 0 &&
      isNavigate
    ) {
      toast("Device is updated from other platform")
      navigate(`management/device-management`);
    }
  };

  const handleDeviceDelete = async (deviceData) => {
    // console.log("Payload device delete ====>", deviceData, userSelectedDevice);
    if (deviceData.device_id === userSelectedDevice.device_id && isNavigate) {
      toast("Device is deleted from other platform")
      navigate(`management/device-management`);
    }
  };
  const handleRelayUpdate = async (deviceData) => {
    // console.log("Payload relay ====>", deviceData, userDeviceSelectedrelay);
    if(deviceData.data.hard_lock == 1){
      navigate(`management/device-management/${deviceData.data.deviceMainId}`);
      return;
    }

    if (
      (deviceData.data.hard_lock === 1 || deviceData.data.is_lock === 1) &&
      deviceData.data.id === userDeviceSelectedrelay.id &&
      isNavigate
    ) {
      toast("Relay is update from other platform")
      navigate(`management/device-management/${userDeviceSelectedrelay.device_id}`);
    }
  };
  // const { masterData } = useAuth();

  const {
    REACT_APP_BASE_URL_SOCKET,
    REACT_APP_BASE_URL_US_SOCKET,
    REACT_APP_BASE_URL_DE_SOCKET,
  } = process.env;
  React.useEffect(() => {
    let showIssue = false;
    let masterData: any = {};
    const masterDatAPIcALL = async () => {
      let masterDataRes = await authApi.masterApiCall("en");
      if (masterDataRes?.status === 200) {
        masterData = masterDataRes?.data?.data;
      } else {
        toast.error(masterDataRes?.message ?? "");
      }
    };

    if (isAuthenticated && initialState.socket == null) {
      let country = window.localStorage.getItem("country");
      let baseURL = REACT_APP_BASE_URL_SOCKET;
      if (country && country !== null && +country == 1) {
        baseURL = REACT_APP_BASE_URL_US_SOCKET;
      } else if (country && country !== null && +country == 2) {
        baseURL = REACT_APP_BASE_URL_DE_SOCKET;
      } else {
        baseURL = REACT_APP_BASE_URL_SOCKET;
      }
      let configration = {
        reconnectionDelayMax: 10000,
        path: "/socket",
        transports: ["websocket"],
        forceNew: true,
        reconnectionAttempts: Infinity,
        autoConnect: true,
        reconnectionDelay: 3000,
        timeout: 4000,
        query: {
          uadt: "2",
        },
        auth: {
          token: token,
        },
        ...masterData?.frontend_device_configuration?.SOCKET_INTERVAL,
      };
      let socketConnent = io(baseURL, {
        ...configration,
      });
      initialState.socket = socketConnent;
      initialState.socket?.on("connect", () => {
        console.log("socket connected", initialState.socket);
        SocketListeners(
          initialState.socket,
          udpateUserDeviceDataSet,
          udpateRelayDataSet,
          udpateSheduleDataSet,
          removeUserDeviceDatafromSet,
          removeRelayDatafromSet,
          addRelayDataSet,
          addSheduleDataSet,
          removeSheduleDatafromSet,
          enableAllShedule,
          disableAllShedule,
          handleAddSharedDevices,
          updateDeviceRelayData,
          setDeviceSettingsUpdateLoader,
          setuserInitalized,
          setuserDownloading,
          setuserFininshed,
          setuserFailed,
          setuserTerminated,
          updateListofFirmwareData,
          removerListDataOfFirmware,
          setUserCountSocketData,
          setDeviceCountSocketData,
          setConnectedDevicSocketData,
          setConnectedDeviceSocketSocketData,
          updateSelectedData,
          handleAddDevices,
          handleRelayUpdate,
          handleDeviceUpdate,
          handleDeviceDelete
        );
        showIssue = false;
        // toast.error("Connectin drop out", { duration: 500000 });
      });
      initialState.socket?.on("connect_error", () => {});
      initialState.socket?.on("disconnect", () => {
        showIssue = true;
      });
      initialState.socket?.on("reconnection_attempt", () => {});
      // console.log("Socket Call Aftrer Connect", initialState.socket);
      if (isAuthenticated && showIssue) {
        toast.error("Connectin drop out", { duration: 50000 });
      }
    } else {
      initialState.socket?.offAny();
      initialState.socket = null;
      showIssue = false;
      // console.log("Socket Call Error", socket);
    }
  }, [isAuthenticated]);
  // useEffect(() => {
  //   window.addEventListener("beforeunload",handleSocketConnection("reload"));
  // }, []);
  // console.log("Socket Call ", socket);

  return (
    <SocketContext.Provider value={initialState}>{props.children}</SocketContext.Provider>
  );
};
