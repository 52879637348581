export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};
export const imageUrl = {
  imageUrl: process.env.REACT_APP_IMAGE_URL,
};
