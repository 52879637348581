// import type { PartialRouteObject } from 'react-router';
import AuthGuard from "components/Guard/AuthGuard";
import LoadingScreen from "components/Common/LoadingScreen";
import DashboardLayout from "components/Dashboard/DashboardLayout";
import MainLayout from "components/MainLayout";
import UserGuard from "components/Guard/UserGuard";
import { USER_ROLES, ACCESS } from "config/constants";
import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import Loder from "components/Loader/loader";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import("pages/Authentication/Login")));
const Registration = Loadable(
  lazy(() => import("pages/Authentication/Registration"))
);
const PasswordRecovery = Loadable(
  lazy(() => import("pages/Authentication/PasswordRecovery"))
);
const ResetPsswod = Loadable(
  lazy(() => import("pages/Authentication/ResetPassword/index"))
);

// Dahboard
const Dashboard = Loadable(lazy(() => import("pages/Dashboard/index")));

const Trail = Loadable(lazy(() => import("pages/AuditTrail/index")));
// User pages
const UserDetails = Loadable(
  lazy(() => import("pages/Management/users/Detail"))
);
const UserList = Loadable(lazy(() => import("pages/Management/users/List")));

const UserEmailList = Loadable(
  lazy(() => import("pages/Management/UserAccountEmail/UserAccountEmail"))
);

// Schedule Pages
const ScheduleList = Loadable(
  lazy(() => import("pages/Management/schedule/List"))
);
const ScheduleListById = Loadable(
  lazy(() => import("pages/Management/schedule/ListById"))
);

// Info Point Pages
const InfoPointList = Loadable(
  lazy(() => import("pages/Management/InfoPoint/List"))
);

// Device Pages
const DeviceList = Loadable(lazy(() => import("pages/Management/device/List")));
const DeviceDetail = Loadable(
  lazy(() => import("pages/Management/device/Detail"))
);
const DeviceEdit = Loadable(lazy(() => import("pages/Management/device/Edit")));
const DeviceSchedule = Loadable(
  lazy(() => import("pages/Management/device/Schedule"))
);

// Device Configuration List
const DeviceConfigureList = Loadable(
  lazy(() => import("pages/Management/DeviceConfiguration/List"))
);
// Device Types List
const DeviceTypesList = Loadable(
  lazy(() => import("pages/Management/DeviceType/DeviceTypeList"))
);
// Device modeles List
const DeviceModelsList = Loadable(
  lazy(() => import("pages/Management/DeviceModels/DeviceModeles"))
);
// Device Firmware List
const DeviceFirmwaresList = Loadable(
  lazy(() => import("pages/Management/DeviceFirmWare/DeviceFirmWare"))
);
// Motor List
const MotorList = Loadable(lazy(() => import("pages/Management/Motor/List")));

// LED List
const LEDList = Loadable(lazy(() => import("pages/Management/LED/List")));
const LEDSort = Loadable(lazy(() => import("pages/Management/LED/Sort")));

// Tutorial List
const TutorialList = Loadable(
  lazy(() => import("pages/Management/tutorial/List"))
);
const TutorialSort = Loadable(
  lazy(() => import("pages/Management/tutorial/Sort"))
);

// Sub Admin Pages
const SubAdminList = Loadable(
  lazy(() => import("pages/Management/subadmin/List"))
);

// Help Pages
const HelpList = Loadable(lazy(() => import("pages/Management/help/List")));
const UserHelpList = Loadable(
  lazy(() => import("pages/Management/help/UserHelpList"))
);

// Other Pages
const Terms = Loadable(lazy(() => import("pages/Management/other/Terms")));
const Feedback = Loadable(
  lazy(() => import("pages/Management/other/Feedback"))
);
const Profile = Loadable(lazy(() => import("pages/Management/other/Profile")));
const ChangePassword = Loadable(
  lazy(() => import("pages/Management/other/ChangePassword"))
);

// Setting
const Setting = Loadable(lazy(() => import("pages/Management/setting")));

const DashboardImagesList = Loadable(
  lazy(() => import("pages/Management/DbImages/List"))
);

// Notification
const NotificationList = Loadable(
  lazy(() => import("pages/Management/notification/List"))
);

// Report Pages
const ActiveUserReport = Loadable(
  lazy(() => import("pages/Management/report/ActiveUserReport"))
);
const UserSession = Loadable(
  lazy(() => import("pages/Management/report/UserSession"))
);
const ActivityLogs = Loadable(
  lazy(() => import("pages/Management/report/ActivityLogs"))
);

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import("pages/Error/401")));
const NotFound = Loadable(lazy(() => import("pages/Error/404")));
const ServerError = Loadable(lazy(() => import("pages/Error/500")));

const routes = (user: any) => [
  {
    path: "auth",
    children: [
      {
        path: "",
        element: (
          <UserGuard>
            <Login />
          </UserGuard>
        ),
      },
      {
        path: "login",
        element: (
          <UserGuard>
            <Login />
          </UserGuard>
        ),
      },
      {
        path: "sign-up",
        element: (
          <UserGuard>
            <Registration />
          </UserGuard>
        ),
      },
      {
        path: "password-recovery",
        element: (
          <UserGuard>
            <PasswordRecovery />
          </UserGuard>
        ),
      },
      {
        path: "reset-password",
        element: (
          <UserGuard>
            <ResetPsswod />
          </UserGuard>
        ),
      },
    ],
  },
  {
    path: "",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element:
          user && ACCESS.dashboard.includes(user?.profile?.role?.id) ? (
            <Dashboard />
          ) : (
            <AuthorizationRequired />
          ),
      },
      {
        path: "profile-management",
        element:
          user && [USER_ROLES.USER].includes(user?.profile?.role?.id) ? (
            <Profile />
          ) : (
            <NotFound />
          ),
      },
      {
        path: "change-password",
        element:
          user && ACCESS.change_password.includes(user?.profile?.role?.id) ? (
            <ChangePassword />
          ) : (
            <NotFound />
          ),
      },
      {
        path: "management",
        children: [
          { path: "", element: <Navigate to="/management/user-management" /> },
          {
            path: "device-management",
            children: [
              {
                path: "",
                element:
                  user &&
                  [USER_ROLES.USER].includes(user?.profile?.role?.id) ? (
                    <DeviceList />
                  ) : user &&
                    ![USER_ROLES.USER].includes(user?.profile?.role?.id) ? (
                    <AuthorizationRequired />
                  ) : (
                    <Loder isLoding={true} />
                  ),
              },
              {
                path: ":deviceId",
                element:
                  user &&
                  [USER_ROLES.USER].includes(user?.profile?.role?.id) ? (
                    <DeviceDetail />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
              {
                path: ":deviceId/edit",
                element:
                  user &&
                  [USER_ROLES.USER].includes(user?.profile?.role?.id) ? (
                    <DeviceEdit />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
              {
                path: "device",
                to: "/device/:portId",
                element:
                  user &&
                  [USER_ROLES.USER].includes(user?.profile?.role?.id) ? (
                    <DeviceSchedule />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
              {
                path: "device/:portId",
                element:
                  user &&
                  [USER_ROLES.USER].includes(user?.profile?.role?.id) ? (
                    <DeviceSchedule />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
              {
                path: ":deviceId/:platformTag",
                element:
                  user &&
                  [USER_ROLES.USER].includes(user?.profile?.role?.id) ? (
                    <DeviceDetail />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
              {
                path: "device/:portId/:platformTag",
                element:
                  user &&
                  [USER_ROLES.USER].includes(user?.profile?.role?.id) ? (
                    <DeviceSchedule />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
            ],
          },
          {
            path: "user-management",
            children: [
              {
                path: "",
                element:
                  user &&
                  [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
                    user?.profile?.role?.id
                  ) ? (
                    <UserList />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
              {
                path: ":userId",
                element:
                  user &&
                  [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
                    user?.profile?.role?.id
                  ) ? (
                    <UserDetails />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
            ],
          },

          {
            path: "sub-admin-management",
            children: [
              {
                path: "",
                element:
                  user &&
                  [USER_ROLES.ADMIN].includes(user?.profile?.role?.id) ? (
                    <SubAdminList />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
              {
                path: ":subAdminId",
                element:
                  user &&
                  [USER_ROLES.ADMIN].includes(user?.profile?.role?.id) ? (
                    <UserDetails />
                  ) : (
                    <AuthorizationRequired />
                  ),
              },
            ],
          },
          {
            path: "user-email-management",
            element:
              user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id) ? (
                <UserEmailList />
              ) : (
                <AuthorizationRequired />
              ),
          },
          // {
          //   path: "ledcode-management",
          //   element:
          //     user &&
          //     [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
          //       user?.profile?.role?.id
          //     ) ? (
          //       <LEDList />
          //     ) : (
          //       <AuthorizationRequired />
          //     ),
          // },
          {
            path: "tutorials",
            element:
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
                user?.profile?.role?.id
              ) ? (
                <TutorialList />
              ) : (
                <AuthorizationRequired />
              ),
          },
          {
            path: "schedule-management",
            element:
              user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id) ? (
                <ScheduleList />
              ) : (
                <AuthorizationRequired />
              ),
          },
          {
            path: "schedule-management/:relayId",
            element:
              user && [USER_ROLES.ADMIN].includes(user?.profile?.role?.id) ? (
                <ScheduleListById />
              ) : (
                <AuthorizationRequired />
              ),
          },
          {
            path: "info-point-management",
            element:
              user &&
              ACCESS.add_edit_info_point.includes(user?.profile?.role?.id) ? (
                <InfoPointList />
              ) : (
                <AuthorizationRequired />
              ),
          },
          // {
          //   path: "ledcode-sort",
          //   element:
          //     user &&
          //     [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
          //       user?.profile?.role?.id
          //     ) ? (
          //       <LEDSort />
          //     ) : (
          //       <AuthorizationRequired />
          //     ),
          // },
          {
            path: "sort-tutorial",
            element:
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
                user?.profile?.role?.id
              ) ? (
                <TutorialSort />
              ) : (
                <AuthorizationRequired />
              ),
          },
        ],
      },
      {
        path: "device-management",
        children: [
          {
            path: "",
            element: <Navigate to="/device-management/device-configuration" />,
          },
          {
            path: "device-configuration",
            element:
              user &&
              ACCESS.add_edit_device_configuration.includes(
                user?.profile?.role?.id
              ) ? (
                <DeviceConfigureList />
              ) : (
                <AuthorizationRequired />
              ),
          },
          {
            path: "device-types",
            element:
              user &&
              ACCESS.add_edit_device_configuration.includes(
                user?.profile?.role?.id
              ) ? (
                <DeviceTypesList />
              ) : (
                <AuthorizationRequired />
              ),
          },
          {
            path: "device-models",
            element:
              user &&
              ACCESS.add_edit_device_configuration.includes(
                user?.profile?.role?.id
              ) ? (
                <DeviceModelsList />
              ) : (
                <AuthorizationRequired />
              ),
          },
          {
            path: "device-firmwares",
            element:
              user &&
              ACCESS.add_edit_device_configuration.includes(
                user?.profile?.role?.id
              ) ? (
                <DeviceFirmwaresList />
              ) : (
                <AuthorizationRequired />
              ),
          },
          {
            path: "motor-management",
            element:
              user &&
              ACCESS.add_edit_motor.includes(user?.profile?.role?.id) ? (
                <MotorList />
              ) : (
                <AuthorizationRequired />
              ),
          },
        ],
      },
      {
        path: "audit-trail",
        children: [
          {
            path: "trail",
            element:
              user &&
              ACCESS.add_edit_device_configuration.includes(
                user?.profile?.role?.id
              ) ? (
                <Trail />
              ) : (
                <AuthorizationRequired />
              ),
          },
        ],
      },
      {
        path: "terms-and-condition",
        element:
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
            user?.profile?.role?.id
          ) ? (
            <Terms />
          ) : (
            <AuthorizationRequired />
          ),
      },
      {
        path: "help",
        element:
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
            user?.profile?.role?.id
          ) ? (
            <HelpList />
          ) : (
            <UserHelpList />
          ),
      },
      {
        path: "feedback",
        element:
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
            user?.profile?.role?.id
          ) ? (
            <Feedback />
          ) : (
            <AuthorizationRequired />
          ),
      },
      {
        path: "settings",
        element:
          user && ACCESS.all_user.includes(user?.profile?.role?.id) ? (
            <Setting />
          ) : (
            <AuthorizationRequired />
          ),
      },
      {
        path: "dashboard-images",
        element:
          user &&
          [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
            user?.profile?.role?.id
          ) ? (
            <DashboardImagesList />
          ) : (
            <AuthorizationRequired />
          ),
      },
      {
        path: "notifications",
        children: [
          {
            path: "",
            element:
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
                user?.profile?.role?.id
              ) ? (
                <NotificationList />
              ) : (
                <AuthorizationRequired />
              ),
          },
        ],
      },
      {
        path: "report",
        children: [
          { path: "", element: <Navigate to="/report/active-user-report" /> },
          {
            path: "active-user-report",
            element:
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
                user?.profile?.role?.id
              ) ? (
                <ActiveUserReport />
              ) : (
                <AuthorizationRequired />
              ),
          },
          {
            path: "activity-log",
            element:
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
                user?.profile?.role?.id
              ) ? (
                <ActivityLogs />
              ) : (
                <AuthorizationRequired />
              ),
          },
          {
            path: "user-session",
            element:
              user &&
              [USER_ROLES.ADMIN, USER_ROLES.SUBADMIN].includes(
                user?.profile?.role?.id
              ) ? (
                <UserSession />
              ) : (
                <AuthorizationRequired />
              ),
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];
export default routes;
