import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { createContext, useReducer } from "react";
import { useAuth } from "../hooks";
import {
  State,
  UserDataContextValue,
} from "./Interface/UserDataManagment/UserDataManagment";
import {
  Action,
  addSubAdminUserListdata,
  addUserListdata,
  removeOwnDewise,
  removeSharedDewise,
  removeSubAdminUserListdata,
  removeUserListdata,
  revokeUserDeviceID,
  setAuthorizedUser,
  SetLanguage,
  SetSettingsData,
  setSubAdminUserListdata,
  setUserDetailsForPage,
  setUserListdata,
  UpdateSettingsData,
  updateSubAdminUserListdata,
  updateUserListdata,
  setUserEmailListData,
  setUserSessionListData,
  setUserActivityList,
  setActivityDataReport,
  setDashBordActivityLogList,
  setUserCountSocket,
  setDeviceCountSocket,
  setConnectedDevicesCountSocket,
  setConnectedSocketCountSocket,
} from "./Interface/UserDataManagment/UserDataManagmentActions";

const initialState: State = {
  languageList: [],
  settings: {
    audit_trail: 0,
    audit_mode: 1,
    email_verification: 0,
    id: 1,
    createdAt: "",
    updatedAt: "",
    max_schedule_time: null,
    max_port_active_time: null,
    max_active_schedules: null,
    admin_email: null,
  },
  authorizedUser: [],
  userList: [],
  userListPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  subAdminUserList: [],
  subAdminUserListPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  userDetails: {
    email: "",
    phone: "",
    status: 0,
    id: 0,
    createdAt: "",
    updatedAt: "",
    auth: {
      id: 0,
      user_id: 0,
      is_mfa_enable: 0,
      mfa_type: 0,
      is_verified: 0,
      createdAt: "",
      updatedAt: "",
    },
    account_tokens: [],
    shared_access: [],
    owned_devices: [],
    profile: {
      first_name: "",
      last_name: "",
      avatar: null,
      address: "",
      user_id: 0,
      role_id: 0,
      language_id: 0,
      country_id: 0,
      zip_code: 0,
      push_notification: 0,
      is_profile_updated: 0,
      is_email_verified: 0,
      id: 0,
    },
  },
  emailList: [],
  emailIstPagination: {
    count: 0,
    page: 0,
    size: 10,
  },
  userSession: [],
  userActivityList: [],
  activityReport: {
    monthly_final_data: {
      label: [],
      active_user: [],
      activities: [],
    },
    yearly_final_data: {
      label: [],
      active_user: [],
      activities: [],
    },
  },
  dashboardUserActivityLog: [],
  deviceCountSocket: {
    active_devices: 0,
    connected_devices: 0,
    total_devices: 0,
  },
  connectedDeviceSocket: {
    active_devices: 0,
    connected_devices: 0,
    total_devices: 0,
  },
  userCountSocket: {
    active_users: 0,
    total_users: 0,
  },
  connectedSocketSocket: {
    active_sockets_connections: 0,
    total_users: 0,
  },
};

const UserDataContext = createContext<UserDataContextValue>({
  ...initialState,
  setLanguageList: () => Promise.resolve(),

  setSettings: () => Promise.resolve(),
  updateSettings: () => Promise.resolve(),

  setAuthorizedUserArray: () => Promise.resolve(),
  revokeUserDevice: () => Promise.resolve(),

  setUserListListData: () => Promise.resolve(),
  addUserListDataSet: () => Promise.resolve(),
  udpateUserListDataSet: () => Promise.resolve(),
  removeUserListDatafromSet: () => Promise.resolve(),

  setSubAdminUserListListData: () => Promise.resolve(),
  addSubAdminUserListDataSet: () => Promise.resolve(),
  udpateSubAdminUserListDataSet: () => Promise.resolve(),
  removeSubAdminUserListDatafromSet: () => Promise.resolve(),

  setUserDetailsPageDat: () => Promise.resolve(),
  removeUserOwnDevice: () => Promise.resolve(),
  removeUserSharedDevice: () => Promise.resolve(),

  setUserEmailList: () => Promise.resolve(),

  setUserSessionList: () => Promise.resolve(),

  setUserActivityListData: () => Promise.resolve(),

  setActivityRepotData: () => Promise.resolve(),

  setDashboardActivityLog: () => Promise.resolve(),

  setUserCountSocketData: () => Promise.resolve(),
  setDeviceCountSocketData: () => Promise.resolve(),
  setConnectedDevicSocketData: () => Promise.resolve(),
  setConnectedDeviceSocketSocketData: () => Promise.resolve(),
});

const handlers: Record<string, (state: State, action: Action) => State> = {
  //  CMS Info Action Interface
  GET_LANG_LIST: (state: State, action: SetLanguage): State => {
    const langListArray = [...action.payload.rows];
    return {
      ...state,
      languageList: langListArray,
      // infoTagsPagination: infoPagination,
    };
  },
  GET_SETTING_DATA: (state: State, action: SetSettingsData): State => {
    const settingData = { ...action.payload };
    return {
      ...state,
      settings: settingData,
    };
  },
  SET_AUTHORIZED_USER_DATA: (
    state: State,
    action: setAuthorizedUser
  ): State => {
    const authorizedUaerList = [...action.payload];
    return {
      ...state,
      authorizedUser: authorizedUaerList,
    };
  },
  UPDATE_SETTING_DATA: (state: State, action: UpdateSettingsData): State => {
    const settingDataUpdate = { ...state.settings, ...action.payload };
    return {
      ...state,
      settings: settingDataUpdate,
    };
  },
  REVOKE_USER_DEVICE: (state: State, action: revokeUserDeviceID): State => {
    const autorizedUserDevice = [...state.authorizedUser];
    const AutorizedDeviceIndex = autorizedUserDevice.findIndex((data) => {
      return data.id === action.payload.data.id;
    });
    const deviceDataList = [
      ...autorizedUserDevice[AutorizedDeviceIndex].devices,
    ];
    let deviceFindIndex = deviceDataList.findIndex((devicedata) => {
      return devicedata.id === action.payload.devicesid.id;
    });
    if (deviceFindIndex !== -1) {
      deviceDataList.splice(deviceFindIndex, 1);
      autorizedUserDevice[AutorizedDeviceIndex].devices = deviceDataList;
    }
    if (autorizedUserDevice[AutorizedDeviceIndex].devices.length === 0) {
      autorizedUserDevice.splice(AutorizedDeviceIndex, 1);
    }
    return {
      ...state,
      authorizedUser: autorizedUserDevice,
    };
  },
  SET_USER_LIST_DATA: (state: State, action: setUserListdata): State => {
    const userListArray = [...action.payload.rows];
    const motorDataPagination = {
      ...state.userListPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      userList: userListArray,
      userListPagination: motorDataPagination,
    };
  },

  ADD_USER_LIST_DATA: (state: State, action: addUserListdata): State => {
    let listDataForAdd = [...state.userList];
    listDataForAdd.push(action.payload);
    let afterCreateUserPagination = {
      ...state.userListPagination,
      count: state.userListPagination.count + 1,
    };
    return {
      ...state,
      userList: listDataForAdd,
      userListPagination: afterCreateUserPagination,
    };
  },
  UPDATE_USER_LIST_DATA: (state: State, action: updateUserListdata): State => {
    let userListDataForUpdate = [...state.userList];
    let userListDataForUpdateIndex = userListDataForUpdate.findIndex((data) => {
      return data.id === action.payload.id;
    });
    if (userListDataForUpdateIndex !== -1) {
      userListDataForUpdate[userListDataForUpdateIndex] = {
        ...userListDataForUpdate[userListDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      userList: userListDataForUpdate,
    };
  },
  REMOVE_USER_LIST_DATA: (state: State, action: removeUserListdata): State => {
    let userListDataForDelete = [...state.userList];
    let userListDataForDeleteIndex = userListDataForDelete.findIndex((data) => {
      return data?.id === action.payload?.id;
    });

    if (userListDataForDeleteIndex !== -1) {
      userListDataForDelete.splice(userListDataForDeleteIndex, 1);
    }
    let afterDeleteUserPagination = {
      ...state.userListPagination,
      count: state.userListPagination.count - 1,
    };
    return {
      ...state,
      userList: userListDataForDelete,
      userListPagination: afterDeleteUserPagination,
    };
  },
  SET_SUB_ADMIN_USER_LIST_DATA: (
    state: State,
    action: setSubAdminUserListdata
  ): State => {
    const subAdminUserListArray = [...action.payload.rows];
    const subAdminUserListDataPagination = {
      ...state.subAdminUserListPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      subAdminUserList: subAdminUserListArray,
      subAdminUserListPagination: subAdminUserListDataPagination,
    };
  },

  ADD_SUB_ADMIN_USER_LIST_DATA: (
    state: State,
    action: addSubAdminUserListdata
  ): State => {
    let subAdminUserlistDataForAdd = [...state.subAdminUserList];
    subAdminUserlistDataForAdd.push(action.payload);
    let afterCreateSubAdminUserPagination = {
      ...state.subAdminUserListPagination,
      count: state.subAdminUserListPagination.count + 1,
    };
    return {
      ...state,
      subAdminUserList: subAdminUserlistDataForAdd,
      subAdminUserListPagination: afterCreateSubAdminUserPagination,
    };
  },
  UPDATE_SUB_ADMIN_USER_LIST_DATA: (
    state: State,
    action: updateSubAdminUserListdata
  ): State => {
    let subAdminUserListDataForUpdate = [...state.subAdminUserList];
    let userListDataForUpdateIndex = subAdminUserListDataForUpdate.findIndex(
      (data) => {
        return data.id === action.payload.id;
      }
    );
    if (userListDataForUpdateIndex !== -1) {
      subAdminUserListDataForUpdate[userListDataForUpdateIndex] = {
        ...subAdminUserListDataForUpdate[userListDataForUpdateIndex],
        ...action.payload,
      };
    }
    return {
      ...state,
      subAdminUserList: subAdminUserListDataForUpdate,
    };
  },
  REMOVE_SUB_ADMIN_USER_LIST_DATA: (
    state: State,
    action: removeSubAdminUserListdata
  ): State => {
    let subAdminUserListDataForDelete = [...state.subAdminUserList];
    let subAdminUserListDataForDeleteIndex =
      subAdminUserListDataForDelete.findIndex((data) => {
        return data.id === action.payload.id;
      });

    if (subAdminUserListDataForDeleteIndex !== -1) {
      subAdminUserListDataForDelete.splice(
        subAdminUserListDataForDeleteIndex,
        1
      );
    }
    let afterDeleteSubAdminUserPagination = {
      ...state.subAdminUserListPagination,
      count: state.subAdminUserListPagination.count - 1,
    };
    return {
      ...state,
      subAdminUserList: subAdminUserListDataForDelete,
      subAdminUserListPagination: afterDeleteSubAdminUserPagination,
    };
  },
  SET_USER_DETAILS_DATA: (
    state: State,
    action: setUserDetailsForPage
  ): State => {
    const userDetails = { ...action.payload };
    return {
      ...state,
      userDetails: userDetails,
    };
  },
  REMOVE_OWN_DEVICE_DATA: (state: State, action: removeOwnDewise): State => {
    let userDetailsData = { ...state.userDetails };
    let userDetailesDevices = [...userDetailsData?.owned_devices];
    const findIndexOfDevice = userDetailesDevices.findIndex((deviceData) => {
      return deviceData.id === action.payload.id;
    });
    if (findIndexOfDevice !== -1) {
      userDetailesDevices.splice(findIndexOfDevice, 1);
    }
    userDetailsData = {
      ...userDetailsData,
      owned_devices: userDetailesDevices,
    };

    return {
      ...state,
      userDetails: userDetailsData,
    };
  },
  REMOVE_SHARED_DEVICE_DATA: (
    state: State,
    action: removeSharedDewise
  ): State => {
    let userDetailsDataShared = { ...state.userDetails };
    let userDetailesSharedDevices = [...userDetailsDataShared?.shared_access];
    const findIndexOfDevice = userDetailesSharedDevices.findIndex(
      (deviceData) => {
        return deviceData.id === action.payload.id;
      }
    );
    if (findIndexOfDevice !== -1) {
      userDetailesSharedDevices.splice(findIndexOfDevice, 1);
    }
    userDetailsDataShared = {
      ...userDetailsDataShared,
      shared_access: userDetailesSharedDevices,
    };
    return {
      ...state,
      userDetails: userDetailsDataShared,
    };
  },
  SET_USER_EMAIL_LIST: (state: State, action: setUserEmailListData): State => {
    const userEmailListArray = [...action.payload.rows];
    const userEmailListDataPagination = {
      ...state.emailIstPagination,
      ...action.payload.pagination,
    };
    return {
      ...state,
      emailList: userEmailListArray,
      emailIstPagination: userEmailListDataPagination,
    };
  },

  SET_USER_SESSION_LIST: (
    state: State,
    action: setUserSessionListData
  ): State => {
    const userSessionlListArray = [...action.payload];
    return {
      ...state,
      userSession: userSessionlListArray,
    };
  },
  SET_USER_ACTIVITY_DATA_LIST: (
    state: State,
    action: setUserActivityList
  ): State => {
    const userActivityListArray = [...action.payload];
    return {
      ...state,
      userActivityList: userActivityListArray,
    };
  },

  SET_USER_ACTIVITY_REPORT_DATA: (
    state: State,
    action: setActivityDataReport
  ): State => {
    const userActivityReportLog = { ...action.payload };
    return {
      ...state,
      activityReport: userActivityReportLog,
    };
  },

  SET_DASHBOARD_USER_ACTIVITY_DATA: (
    state: State,
    action: setDashBordActivityLogList
  ): State => {
    const userDashboardActivityListArray = [...action.payload];
    return {
      ...state,
      dashboardUserActivityLog: userDashboardActivityListArray,
    };
  },

  SET_USER_COUNT_DATA: (state: State, action: setUserCountSocket): State => {
    const userCountSocketData = { ...action.payload };
    return {
      ...state,
      userCountSocket: userCountSocketData,
    };
  },

  SET_DEVICE_COUNT_DATA: (
    state: State,
    action: setDeviceCountSocket
  ): State => {
    const deviceCountSocketData = { ...action.payload };
    return {
      ...state,
      deviceCountSocket: deviceCountSocketData,
    };
  },

  SET_CONNECTED_DEVICE_DATA: (
    state: State,
    action: setConnectedDevicesCountSocket
  ): State => {
    const connectedDeviceSocketData = { ...action.payload };
    return {
      ...state,
      connectedDeviceSocket: connectedDeviceSocketData,
    };
  },
  SET_CONNECTED_SOCKET_DATA: (
    state: State,
    action: setConnectedSocketCountSocket
  ): State => {
    const connectedSocketSocketData = { ...action.payload };
    return {
      ...state,
      connectedSocketSocket: connectedSocketSocketData,
    };
  },
};
interface UserDataProvideProps {
  children: ReactNode;
}
const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
export const UserDataProvide: FC<UserDataProvideProps> = (props) => {
  const { isAuthenticated } = useAuth();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const setLanguageList = (payload) => {
    dispatch({ type: "GET_LANG_LIST", payload });
  };
  const setSettings = (payload) => {
    dispatch({ type: "GET_SETTING_DATA", payload });
  };
  const updateSettings = (payload) => {
    dispatch({ type: "UPDATE_SETTING_DATA", payload });
  };
  const setAuthorizedUserArray = (payload) => {
    dispatch({ type: "SET_AUTHORIZED_USER_DATA", payload });
  };
  const revokeUserDevice = (data, id) => {
    dispatch({
      type: "REVOKE_USER_DEVICE",
      payload: { data: data, devicesid: id },
    });
  };
  const setUserListListData = (payload) => {
    dispatch({
      type: "SET_USER_LIST_DATA",
      payload,
    });
  };
  const addUserListDataSet = (payload) => {
    dispatch({
      type: "ADD_USER_LIST_DATA",
      payload,
    });
  };
  const udpateUserListDataSet = (payload) => {
    dispatch({
      type: "UPDATE_USER_LIST_DATA",
      payload,
    });
  };
  const removeUserListDatafromSet = (payload) => {
    dispatch({
      type: "REMOVE_USER_LIST_DATA",
      payload,
    });
  };
  const setSubAdminUserListListData = (payload) => {
    dispatch({
      type: "SET_SUB_ADMIN_USER_LIST_DATA",
      payload,
    });
  };
  const addSubAdminUserListDataSet = (payload) => {
    dispatch({
      type: "ADD_SUB_ADMIN_USER_LIST_DATA",
      payload,
    });
  };
  const udpateSubAdminUserListDataSet = (payload) => {
    dispatch({
      type: "UPDATE_SUB_ADMIN_USER_LIST_DATA",
      payload,
    });
  };
  const removeSubAdminUserListDatafromSet = (payload) => {
    dispatch({
      type: "REMOVE_SUB_ADMIN_USER_LIST_DATA",
      payload,
    });
  };
  const setUserDetailsPageDat = (payload) => {
    dispatch({
      type: "SET_USER_DETAILS_DATA",
      payload,
    });
  };
  const removeUserOwnDevice = (payload) => {
    dispatch({
      type: "REMOVE_OWN_DEVICE_DATA",
      payload,
    });
  };
  const removeUserSharedDevice = (payload) => {
    dispatch({
      type: "REMOVE_SHARED_DEVICE_DATA",
      payload,
    });
  };
  const setUserEmailList = (payload) => {
    dispatch({
      type: "SET_USER_EMAIL_LIST",
      payload,
    });
  };
  const setUserSessionList = (payload) => {
    dispatch({
      type: "SET_USER_SESSION_LIST",
      payload,
    });
  };
  const setUserActivityListData = (payload) => {
    dispatch({
      type: "SET_USER_ACTIVITY_DATA_LIST",
      payload,
    });
  };
  const setActivityRepotData = (payload) => {
    dispatch({
      type: "SET_USER_ACTIVITY_REPORT_DATA",
      payload,
    });
  };

  const setDashboardActivityLog = (payload) => {
    dispatch({
      type: "SET_DASHBOARD_USER_ACTIVITY_DATA",
      payload,
    });
  };

  const setUserCountSocketData = (payload) => {
    dispatch({
      type: "SET_USER_COUNT_DATA",
      payload,
    });
  };
  const setDeviceCountSocketData = (payload) => {
    dispatch({
      type: "SET_DEVICE_COUNT_DATA",
      payload,
    });
  };
  const setConnectedDevicSocketData = (payload) => {
    dispatch({
      type: "SET_CONNECTED_DEVICE_DATA",
      payload,
    });
  };
  const setConnectedDeviceSocketSocketData = (payload) => {
    dispatch({
      type: "SET_CONNECTED_SOCKET_DATA",
      payload,
    });
  };

  return (
    <UserDataContext.Provider
      value={{
        ...state,
        setLanguageList,
        setSettings,
        updateSettings,

        setAuthorizedUserArray,

        revokeUserDevice,

        setUserListListData,
        addUserListDataSet,
        udpateUserListDataSet,
        removeUserListDatafromSet,

        setSubAdminUserListListData,
        addSubAdminUserListDataSet,
        udpateSubAdminUserListDataSet,
        removeSubAdminUserListDatafromSet,

        setUserDetailsPageDat,
        removeUserOwnDevice,
        removeUserSharedDevice,

        setUserEmailList,

        setUserSessionList,

        setUserActivityListData,

        setActivityRepotData,

        setDashboardActivityLog,

        setUserCountSocketData,
        setDeviceCountSocketData,
        setConnectedDevicSocketData,
        setConnectedDeviceSocketSocketData,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
UserDataProvide.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserDataContext;
