import type { ListItemProps } from "@material-ui/core";
import { Box, Button, Collapse, ListItem } from "@material-ui/core";
import { ChevronRight, KeyboardArrowDown } from "@material-ui/icons";
import PropTypes, { exact } from "prop-types";
import type { FC, ReactNode } from "react";
import { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  iconType?: string;
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    iconType,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0,
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? (
              <ChevronRight fontSize="small" />
            ) : (
              <KeyboardArrowDown fontSize="small" />
            )
          }
          className={`${
            !iconType || iconType === "" ? "navitemBtn" : "navitemBtnTwo"
          }`}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: "text.primary",
            fontWeight: "fontWeightRegular",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: "8px",
            py: "12px",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            fontSize: "13px",
            lineHeight: "14px",
            ...(active && {
              color: "primary.main",
              fontWeight: "fontWeightBold",
              "& g": {
                fill: "#16b3d5",
                color: "#16b3d5",
              },
              "& svg": {
                color: "#16b3d5",
              },
              "& path": {
                fill: !iconType || iconType === "" ? "#16b3d5" : "inherit",
                stroke: !iconType || iconType === "" ? "inherit" : "#16b3d5",
                color: "#16b3d5",
              },
            }),
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        {open ? <Collapse in={open}>{children}</Collapse> : ""}
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
    >
      <Button
        component={path && RouterLink}
        startIcon={icon}
        // className={"navitemBtn"}
        sx={{
          color: "text.primary",
          fontWeight: "fontWeightRegular",
          justifyContent: "flex-start",
          textAlign: "left",
          pl: `${paddingLeft}px`,
          pr: "8px",
          py: "12px",
          textTransform: "none",
          width: "100%",
          fontSize: "13px",
          lineHeight: "20px",
          ...(active && {
            color: "primary.main",
            fontWeight: "fontWeightBold",
            "& g": {
              fill: "#16b3d5",
              color: "#16b3d5",
            },
            "& svg": {
              color: "#16b3d5",
            },
            "& path": {
              fill: !iconType || iconType === "" ? "#16b3d5" : "inherit",
              stroke: !iconType || iconType === "" ? "inherit" : "#16b3d5",
              color: "#16b3d5",
            },
          }),
        }}
        variant="text"
        to={path}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
        {info}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
