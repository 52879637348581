import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import PropTypes from "prop-types";
import type { FC } from "react";
import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

interface RadioProps {
  data: {
    label: string;
    value: string | number;
  }[];
  defaultValue: string | number;
  classes: any;
  // onChange: any;
  [key: string]: any;
}

const styles = (theme) => ({
  label: {
    color: theme.palette.text.secondary,
  },
});

const RadioGrp: FC<RadioProps> = ({
  classes,
  data,
  defaultValue,
  // onChange,
  ...other
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    // onChange(event.target.value);
  };

  return (
    <RadioGroup
      aria-label="firmware"
      name="firmware"
      value={defaultValue}
      onChange={handleChange}
      row
    >
      {data &&
        data.map((item) => (
          <FormControlLabel
            value={item.value}
            control={<Radio size="small" />}
            label={item.label}
            classes={{
              label: classes.label,
            }}
            {...other}
          />
        ))}
    </RadioGroup>
  );
};

RadioGrp.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  classes: PropTypes.any,
};

export default withStyles(styles)(RadioGrp);
