import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

interface ButtonProps {
  label?: string | any;
  type?: "button" | "submit" | "reset";
  [key: string]: any;
}

const SubmitButton: FC<ButtonProps> = ({ label, type, ...other }) => {
  const { t } = useTranslation();

  // console.log("other?.disabled ", other?.disabled);

  return (
    <Button
      sx={{ mt: 1, borderRadius: "40px" }}
      type={type}
      variant="contained"
      className={other?.disabled ? "" : "theme_btn"}
      {...other}
    >
      {t(label) || t("Submit")}
    </Button>
  );
};

SubmitButton.defaultProps = {
  label: "Submit",
  type: "submit",
};

SubmitButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
};

export default SubmitButton;
