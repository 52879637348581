import CancelAndBackButton from "components/Form/button/CancelAndBackButton";
import HeaderButton from "components/Header/HeaderButton";
import { useUser } from "hooks";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";

interface HeaderProps {
  isCancelBtn?: boolean;
  cancelTo?: string;
  AddButton?: {
    Button?: boolean;
    BtnLable?: string;
    BtnLink?: string;
    BtnAccess?: Array<number>;
    BtnComponent?: ReactNode;
    [key: string]: any;
  };
}

const LoadButton = (ButtonProps, currentRole) => {
  if (ButtonProps) {
    const { BtnAccess, BtnLable, BtnLink, BtnComponent } = ButtonProps;
    if (BtnAccess) {
      if (BtnAccess.includes(currentRole)) {
        return (
          <HeaderButton
            BtnLable={BtnLable}
            BtnLink={BtnLink}
            BtnComponent={BtnComponent}
            {...ButtonProps}
          />
        );
      }
    } else {
      return (
        <HeaderButton
          BtnLable={BtnLable}
          BtnLink={BtnLink}
          BtnComponent={BtnComponent}
          {...ButtonProps}
        />
      );
    }
  }
  return null;
};

const ContentHeaderRight: FC<HeaderProps> = ({
  isCancelBtn,
  cancelTo,
  AddButton,
}) => {
  const { user } = useUser();
  return (
    <>
      {LoadButton(AddButton, user && user?.profile?.role?.id)}
      {isCancelBtn ? <CancelAndBackButton to={cancelTo} /> : null}
    </>
  );
};

ContentHeaderRight.propTypes = {
  isCancelBtn: PropTypes.bool,
  cancelTo: PropTypes.string,
  AddButton: PropTypes.shape({
    Button: PropTypes.bool,
    BtnLable: PropTypes.string,
    BtnLink: PropTypes.string,
    BtnAccess: PropTypes.array,
    BtnComponent: PropTypes.node,
  }),
};

export default ContentHeaderRight;
