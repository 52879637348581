import {
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';

interface GridProps {
  children: ReactNode,
  variant?: {
    [key: string]: any
  }
}

const LargeGrid: FC<GridProps> = ({ children, variant }) => (
  <Grid
    item
    md={8}
    xs={6}
    justifyContent="flex-start"
    {...variant}
  >
    {children}
  </Grid>
);

LargeGrid.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.shape({})
};

export default LargeGrid;
