import {
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';

const SmallGrid: FC<{ children: ReactNode, [key: string]: any }> = ({ children, ...other }) => (
  <Grid
    item
    md={4}
    xs={6}
    justifyContent="flex-start"
    {...other}
  >
    {children}
  </Grid>
);

SmallGrid.propTypes = {
  children: PropTypes.node
};

export default SmallGrid;
