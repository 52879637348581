import { SocketAction as Events } from "./Actions";
import toast from "react-hot-toast";
const SocketListeners = (
  socket,
  udpateUserDeviceDataSet,
  udpateRelayDataSet,
  udpateSheduleDataSet,
  removeUserDeviceDatafromSet,
  removeRelayDatafromSet,
  addRelayDataSet,
  addSheduleDataSet,
  removeSheduleDatafromSet,
  enableAllShedule,
  disableAllShedule,
  handleAddSharedDevices,
  updateDeviceRelayData,
  setDeviceSettingsUpdateLoader,
  setuserInitalized,
  setuserDownloading,
  setuserFininshed,
  setuserFailed,
  setuserTerminated,
  updateListofFirmwareData,
  removerListDataOfFirmware,
  setUserCountSocketData,
  setDeviceCountSocketData,
  setConnectedDevicSocketData,
  setConnectedDeviceSocketSocketData,
  updateSelectedData,
  handleAddDevices,
  handleRelayUpdate,
  handleDeviceUpdate,
  handleDeviceDelete
) => {
  console.log("Started Listing", socket);
  socket?.onAny((ev: string, payload: any) => {
    // console.log("Before Switch Case", ev, payload);
    switch (ev) {
      case Events.SERVER.DEVICE_ADDED:
        console.log("Events.SERVER.DEVICE_ADDED", payload);
        // addDevicesInDataSet(payload.data);
        handleAddDevices(payload.data);
        break;
      case Events.SERVER.DEVICE_UPDATED:
        console.log("Events.SERVER.DEVICE_UPDATED", payload);
        udpateUserDeviceDataSet(payload.data);
        // setDeviceData(payload.data);
        updateListofFirmwareData(payload.data);
        handleDeviceUpdate(payload);
        break;
      case Events.SERVER.DEVICE_DELETED:
        console.log("Events.SERVER.DEVICE_DELETED", payload);
        removeUserDeviceDatafromSet(payload.data);
        handleDeviceDelete(payload);
        break;
      case Events.SERVER.DEVICE_ACCESS_GRANTED:
        console.log("Events.SERVER.DEVICE_ACCESS_GRANTED", payload);
        handleAddSharedDevices(payload.data);
        break;
      case Events.SERVER.DEVICE_ACCESS_DELETED:
        console.log("Events.SERVER.DEVICE_ACCESS_DELETED", payload); // { data }
        removeUserDeviceDatafromSet(payload.data);
        break;

      case Events.SERVER.RELAY_ADDED:
        console.log("Events.SERVER.RELAY_ADDED", payload);
        addRelayDataSet(payload.data);
        break;

      case Events.SERVER.RELAY_DELETED:
        console.log("Events.SERVER.RELAY_DELETED", payload);
        removeRelayDatafromSet(payload.data);
        break;

      case Events.SERVER.RELAY_UPDATED:
        console.log("Events.SERVER.RELAY_UPDATED", payload);
        updateDeviceRelayData(payload.data);
        udpateRelayDataSet(payload.data);
        handleRelayUpdate(payload);
        break;

      case Events.SERVER.SCHEDULE_ADDED:
        console.log("Events.SERVER.SCHEDULE_ADDED", payload);
        // addSheduleDataSet(payload.data);
        // let dataAdded: any = {
        //   ...payload,
        //   from: "ADDED",
        // };
        // updateSelectedData(dataAdded);
        break;
      case Events.SERVER.SCHEDULE_ENABLED_ALL:
        console.log("Events.SERVER.SCHEDULE_ENABLED_ALL", payload);
        enableAllShedule(payload.data);
        break;
      case Events.SERVER.SCHEDULE_DISABLE_ALL:
        console.log("Events.SERVER.SCHEDULE_DISABLE_ALL", payload);
        disableAllShedule(payload.data);
        break;
      case Events.SERVER.SCHEDULE_DELETED:
        console.log("Events.SERVER.SCHEDULE_DELETED", payload);
        removeSheduleDatafromSet(payload.data);
        let data: any = {
          ...payload,
          from: "DELETED",
        };
        updateSelectedData(data);
        break;

      case Events.SERVER.SCHEDULE_UPDATED:
        console.log("Events.SERVER.SCHEDULE_UPDATED", payload);
        // udpateSheduleDataSet(payload.data);
        break;

      case Events.CLIENT.CLEAR_WIFI_SSID:
        console.log("Events.CLIENT.CLEAR_WIFI_SSID", payload);
        break;

      case Events.CLIENT.TURN_OFF_ALL_PORTS:
        console.log("Events.CLIENT.TURN_OFF_ALL_PORTS", payload);
        break;

      case Events.CLIENT.RTC_VOLTAGE:
        console.log("Events.CLIENT.RTC_VOLTAGE", payload);
        break;

      case Events.CLIENT.GET_WIFI_SSID:
        console.log("Events.CLIENT.GET_WIFI_SSID", payload);
        break;

      case Events.CLIENT.GET_WIFI_SSID:
        console.log("Events.CLIENT.GET_WIFI_SSID", payload);
        break;

      case Events.CLIENT.GET_SPEED:
        console.log("Events.CLIENT.GET_SPEED", payload);
        break;

      case Events.CLIENT.GET_SCHEDULER:
        console.log("Events.CLIENT.GET_SCHEDULER", payload);
        break;

      case Events.CLIENT.GET_PUMP_LOCK_STATUS:
        console.log("Events.CLIENT.GET_PUMP_LOCK_STATUS", payload);
        break;

      case Events.CLIENT.GET_PORT_TIME_TO_OFF:
        console.log("Events.CLIENT.GET_PORT_TIME_TO_OFF", payload);
        break;

      case Events.CLIENT.GET_PORT_STATUS:
        console.log("Events.CLIENT.GET_PORT_STATUS", payload);
        break;

      case Events.CLIENT.GET_FW_VERSION:
        console.log("Events.CLIENT.GET_FW_VERSION", payload);
        break;

      case Events.CLIENT.GET_FLOODED_STATE:
        console.log("Events.CLIENT.GET_FLOODED_STATE", payload);
        break;

      case Events.CLIENT.GET_DEVICE_TYPE:
        console.log("Events.CLIENT.GET_DEVICE_TYPE", payload);
        break;

      case Events.CLIENT.GET_DEVICE_TIMEZONE:
        console.log("Events.CLIENT.GET_DEVICE_TIMEZONE", payload);
        break;

      case Events.CLIENT.GET_DEVICE_TIME:
        console.log("Events.CLIENT.GET_DEVICE_TIME", payload);
        break;

      case Events.ADMIN.AUDIT.ADMIN_LOGS:
        console.log("Events.ADMIN.AUDIT.ADMIN_LOGS", payload);
        break;

      case Events.ADMIN.AUDIT.USER_LOGS:
        console.log("Events.ADMIN.AUDIT.USER_LOGS", payload);
        break;
      case Events.ADMIN.USERS_COUNT:
        console.log("Events.ADMIN.USERS_COUNT", payload);
        setUserCountSocketData(payload);
        break;
      case Events.ADMIN.CONNECTED_DEVICES:
        console.log("Events.ADMIN.CONNECTED_DEVICES", payload);
        setConnectedDevicSocketData(payload);
        break;
      case Events.ADMIN.SOCKET_CONNECTIONS:
        console.log("Events.ADMIN.SOCKET_CONNECTIONS", payload);
        setConnectedDeviceSocketSocketData(payload);
        break;
      case Events.ADMIN.DEVICES_COUNT:
        console.log("Events.ADMIN.DEVICES_COUNT", payload);
        setDeviceCountSocketData(payload);
        break;

      case Events.CLIENT.OTA_INITIATED:
        setDeviceSettingsUpdateLoader(true);
        setuserInitalized(true);
        setuserDownloading(false);
        setuserFininshed(false);
        setuserFailed(false);
        setuserTerminated(false);
        console.log("Events.CLIENT.OTA_INITIATED", payload);
        break;
      case Events.CLIENT.OTA_DOWNLOADING_UPDATE:
        toast.success(`${payload.data.msg}`);
        setDeviceSettingsUpdateLoader(true);
        setuserInitalized(false);
        setuserDownloading(true);
        setuserFininshed(false);
        setuserFailed(false);
        setuserTerminated(false);
        console.log("Events.CLIENT.OTA_DOWNLOADING_UPDATE", payload);
        break;
      case Events.CLIENT.OTA_FINISHED:
        toast.success(`${payload.data.msg}`);
        setuserInitalized(false);
        setuserDownloading(false);
        setuserFininshed(true);
        setuserFailed(false);
        setuserTerminated(false);
        setDeviceSettingsUpdateLoader(false);
        removerListDataOfFirmware(payload.data);
        console.log("Events.CLIENT.OTA_FINISHED", payload);
        break;

      case Events.CLIENT.OTA_FAILED:
        toast.error(`${payload.data.msg}`);
        setuserInitalized(false);
        setuserDownloading(false);
        setuserFininshed(false);
        setuserFailed(true);
        setuserTerminated(false);
        setDeviceSettingsUpdateLoader(false);
        console.log("Events.OTA_FAILED", payload);
        break;
      case Events.CLIENT.OTA_TERMINATED:
        toast.error(`${payload.data.msg}`);
        setuserInitalized(false);
        setuserDownloading(false);
        setuserFininshed(false);
        setuserFailed(false);
        setuserTerminated(true);
        setDeviceSettingsUpdateLoader(false);
        console.log("Events.CLIENT.OTA_TERMINATED", payload);
        break;
    }
  });
};
export default SocketListeners;
