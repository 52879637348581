import React, { useState } from "react";
import { css } from "@emotion/react";
import { Typography } from "@material-ui/core";
import { BounceLoader, MoonLoader } from "react-spinners";
interface loderPrpos {
  isLoding: boolean;
  color?: string;
}
const Loder: React.FC<loderPrpos> = (props) => {
  // console.log("Loader Props ", props);

  let [color, setColor] = useState(props?.color ?? "#16b3d5");
  const override = css`
    display: block;
    position: relative;
  `;
  return (
    <div className={`loderWraper`}>
      {/* <Typography>Loader</Typography> */}
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <MoonLoader
          color={color}
          loading={props.isLoding}
          css={override}
          size={50}
        />
      </div>
    </div>
  );
};

export default Loder;
