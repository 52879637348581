import Wrapper from "components/Authentication";
import { LoginForm, Totp } from "components/Authentication/login";
import { useAuth } from "hooks";
import type { FC } from "react";

const Login: FC = () => {
  const { isMFAEnabled, MFAType } = useAuth();

  const LoadForm = () => {
    if (isMFAEnabled) {
      if (MFAType) {
        return <Totp />;
      }
      return <Totp />;
    }
    return <LoginForm />;
  };

  return (
    <Wrapper title={isMFAEnabled ? "2-FA Verification":"Login"}>
      <LoadForm />
    </Wrapper>
  );
};

export default Login;
