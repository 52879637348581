// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const { REACT_APP_VAPID_KEY } = process.env;
import { isSafari } from "@firebase/util";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyAoAlC6S0sjjg9pMiM_tDxijDCRfZ54xPs",
  authDomain: "speckpump-test.firebaseapp.com",
  projectId: "speckpump-test",
  storageBucket: "speckpump-test.appspot.com",
  messagingSenderId: "1027062805237",
  appId: "1:1027062805237:web:71f4459b1c665438320bbc",
};
let isSafarTest = isSafari();

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

let mobile = false;
if (
  window.innerWidth <= 900 ||
  (window.innerWidth <= 1100 && window.innerHeight <= 900)
) {
  mobile = true;
}
export const messaging = !mobile && !isSafarTest && getMessaging(app);
const publicKey = REACT_APP_VAPID_KEY;

export const getTokenFCM = async (setTokenFound) => {
  let currentToken = "";
  currentToken = await new Promise((resolve, reject) => {

    
    getToken(messaging, { vapidKey: publicKey })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          resolve(currentToken);
          setTokenFound(true);
        } else {
          // Show permission request UI
          // console.log(
          //   "No registration token available. Request permission to generate one."
          // );
          reject(
            "No registration token available. Request permission to generate one."
          );
          setTokenFound(false);
          // ...
        }
      })
      .catch((err) => {
        console.log(err.message)
        // console.log("An error occurred while retrieving token. ", err);
        reject(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
      });
  });
  // console.log("currentToken ==>", currentToken);

  return currentToken;
};

export const onMessageListener = () => {
  return new Promise((resolve, reject) => {
    onMessage(messaging, (payload) => {
      // console.log("Message received. Setups", payload);
      new Notification(payload.notification.title, {
        body: payload.notification.body,
      });
      resolve(payload);
    });
  });
};
