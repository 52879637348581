import type { FC } from 'react';
// import type { Theme } from '@material-ui/core';
// import type { SxProps } from '@material-ui/system';
import PropTypes from 'prop-types';

interface LogoProps {
  width?: number | string,
  height?: number | string,
  variation?: string
}

const Logo: FC<LogoProps> = ({ height, width, variation }) => (
  <img
    src={`${variation === 'whiteLogo' ? '/static/app_logo_white.svg' : '/static/app_logo.svg'}`}
    height={height || 100}
    width={width || 150}
    alt="app_logo"
  />
);

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  variation: PropTypes.string
};

export default Logo;
