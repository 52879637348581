import { experimentalStyled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      paddingTop: 64
    }
  )
);

const MainLayout: FC<MainLayoutProps> = ({ children }) => (
  <MainLayoutRoot>
    {children || <Outlet />}
  </MainLayoutRoot>
);

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
