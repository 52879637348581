import {
  Box,
  Card,
  CardContent,
  Typography,
  Container,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Logo from "components/Logo";
import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface AuthProps {
  title: string;
  backIcon?: boolean;
  children: ReactNode;
}

const index: FC<AuthProps> = ({ title, backIcon, children }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 8,
          }}
        >
          <RouterLink to="/">
            <Logo width={200} height={"auto"} />
          </RouterLink>
        </Box>
        <Card sx={{ borderRadius: "16px" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 4,
            }}
          >
            <Typography color="textPrimary" gutterBottom variant="h5">
              {t(title)}
            </Typography>
            {children}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

index.propTypes = {
  title: PropTypes.string,
  backIcon: PropTypes.bool,
  children: PropTypes.node,
};

export default index;
